import { expoInOut } from "svelte/easing";
import { get } from "svelte/store";
import { slide } from "svelte/transition";
import { getProductsFromAlgoliaAsync, OPTION_RESPONSE_FIELDS } from "../modules/common/services/algolia";
import { unxEscapeSpecialCharacters } from "../modules/common/services/unbxd";
import { getCartLoginDetails } from "../modules/header/services/header-service";
import { addToWishlist } from "../modules/home/services/home-data-services";
import { handleCartResponse } from "../modules/product-details/services/pdp-data-services";
import { openRestockablePopup, restockableProductContent } from "../modules/product-details/store/pdp_store";
import { categoryStaticBottomContent, categoryStaticTopContent, compareProducts, isCategoryStaticBottomContent, isCategoryStaticTopContent, islocationDisabled, unxSelectedRatings, unxSortOptions } from "../modules/product-listing-search/store/plp-data-store";
import { getAppConfig } from "./config/app-config";
const lsKey = "compare-products";


const { algoliaConfig: { filterInfo, filtersTobeExcludedInURL }, encodedContextPath, themeResourcePath, currency, currencyDecimal, lang, sponsoredKey, extraSponsoredKey, userDefaultCityCode, userSelectedCityCode, joodMegaSaleTag, primeLevel, svelteATCEnabled, unbxdEnabled } = getAppConfig();

const filterInfoMap = (<any>Object).groupBy(filterInfo, x => x.code);

export function checkForMegaSaleProduct(productTags) {
    let isMegaSaleProduct = [];
    if (productTags?.length) {
        isMegaSaleProduct = productTags.filter(tag => {
            return tag.productStatus === joodMegaSaleTag ? tag.productStatus : '';
        });
    }
    return isMegaSaleProduct;
}

export function isMobileWidth() {
    return window.matchMedia("only screen and (max-width: 899px)").matches;
}

export function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    }) || isMobileWidth();
}

export function getLangSpecificFieldname(fieldname, _lang = lang, suffix = '') {
    if (_lang.length > 0) {
        _lang = _lang[0].toUpperCase() + _lang.substr(1);
    } else {
        _lang = 'Ar';
    }
    return `${fieldname}${_lang}${suffix}`;
}

export function getCountryList(lang) {
    let countryList = [
        { id: 'sa', nameKey: 'header.saudi-arabia', image: `${themeResourcePath}/images/ksa-flag.png` },
        { id: 'bh', nameKey: 'header.bahrain', image: `${themeResourcePath}/images/bah-flag.png` },
        { id: 'om', nameKey: 'header.oman', image: `${themeResourcePath}/images/oma-flag.png` },
        { id: 'eg', nameKey: 'header.egypt', image: `${themeResourcePath}/images/egy-flag.png` }
    ];

    return countryList
        .map(item => Object.assign(item, { link: `/${lang.toLowerCase()}-${item.id}` }));
};
export function changeCountry(href) {
    const newLink = window.location.href.replace(encodedContextPath, href);
    window.location.assign(newLink);
}
export function isDirectionRTL() {
    return document.dir === 'rtl';
}

export function parseQueryString(queryString) {
    if (queryString.length === 0) return {};

    const keyValueString = queryString.startsWith('?') ? queryString.substr(1) : queryString;
    const objectArray = keyValueString
        .split('&')
        .filter(x => x !== '')
        .map(x => { return { [x.substr(0, x.indexOf('='))]: x.substr(x.indexOf('=') + 1) } });

    return Object.assign({}, ...objectArray);
}

export function getRandomString(prefix, length) {
    length = Math.max(length, prefix.length);
    const remLength = length - prefix.length;
    const randomString = Array(remLength).fill(0).map(() => '' + (Math.floor(Math.random() * 10))).join('');
    return `${prefix}${randomString}`;
}

export function clamp(num, min, max) {
    return num < min ? min : num > max ? max : num;
}

export function linear(domain, range) {
    const d0 = domain[0];
    const r0 = range[0];
    const m = (range[1] - r0) / (domain[1] - d0);

    /** @param {number} num */
    function scale(num) {
        return r0 + (num - d0) * m;
    }

    scale.inverse = () => linear(range, domain);

    return scale;
}

export function isValidColor(strColor) {
    var s = new Option().style;
    s.color = strColor;
    return s.color == strColor.toLowerCase();
}

export function parseLatestOrder(customerLastOrderDetails) {
    if (customerLastOrderDetails === null || customerLastOrderDetails.trim() === '') {
        return false;
    }

    // "{WES900295001=16 June}"
    let lastOrderRE = /\{(.*)=(.*)\}/;
    const reResult = lastOrderRE.exec(customerLastOrderDetails);

    if (reResult !== null) {
        const [, orderId, orderDate] = reResult;
        return { orderId, orderDate };
    }
    return false;
}

export function getLangChangeUrl() {
    const currentUrl = window.location.href;
    const { lang, countryCode } = getAppConfig();
    const currentLocale = `${lang}-${countryCode}`.toLowerCase();
    const newLanguage = lang === 'en' ? 'ar' : 'en';
    const newLocale = `${newLanguage}-${countryCode}`.toLowerCase();

    const langChangeUrl = currentUrl.replace(currentLocale, newLocale);
    return langChangeUrl;
}

export function getCookie(name) {

    const cookieObj = Object.fromEntries(
        document.cookie.split(';')
            .map(x => x.trim().split('='))
    );

    return (name in cookieObj) ? cookieObj[name] : null;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function zeroPadNumber(num) {
    if (num === undefined) {
        return '';
    }
    let nStr = num.toString();
    if (nStr.length === 1) {
        nStr = '0' + nStr;
    }
    return nStr;
}
export function formatDealTimer(dealTimer) {
    return Object.assign({}, dealTimer, {
        hDiff: zeroPadNumber(dealTimer.hDiff),
        minDiff: zeroPadNumber(dealTimer.minDiff),
        secDiff: zeroPadNumber(dealTimer.secDiff)
    });
}

/**
 * Function ported from acc.productlist-algolia.js
 * @param endDateDeal received from algolia response => hit objects
 */
export function createDealTimerObject(endDateDeal) {
    // time correction 
    if (typeof endDateDeal === "string") {
        if (endDateDeal.endsWith('Z') || endDateDeal.endsWith('z')) {
            endDateDeal = endDateDeal.replace(/z/i, '+0300');
        } else if (!endDateDeal.includes('+')) {
            // endDateDeal does not have 'z', 'Z' or '+'
            // append +0300 to treat it in KSA timezone
            endDateDeal += '+0300';
        }
    }

    const dealDate = new Date(new Date(endDateDeal).toUTCString());
    const nowDate = new Date(new Date().toUTCString());
    let msDifference = dealDate.getTime() - nowDate.getTime();

    const days = Math.floor(msDifference / (1000 * 60 * 60 * 24));
    msDifference %= (1000 * 60 * 60 * 24);
    const hours = Math.floor(msDifference / (1000 * 60 * 60));
    msDifference %= (1000 * 60 * 60);
    const minutes = Math.floor(msDifference / (1000 * 60));
    msDifference %= (1000 * 60);
    const seconds = Math.floor(msDifference / (1000));

    const h = hours > 9 ? hours.toString() : '0' + hours.toString();
    const m = minutes > 9 ? minutes.toString() : '0' + minutes.toString();
    const s = seconds > 9 ? seconds.toString() : '0' + seconds.toString();

    return {
        deals: true,
        hDiff: h,
        minDiff: m,
        secDiff: s,
        distance: null
    };
}

export function loadProdFromLS() {
    const compareProdLS = localStorage.getItem(lsKey);
    if (compareProdLS) {
        compareProducts.set(JSON.parse(compareProdLS));
    } else {
        compareProducts.set([]);
    }
};

export function removeAllProdFromLS() {
    compareProducts.set([]);
    var pageUrl = `${location.origin}${encodedContextPath}`
    var url = `${pageUrl}/compare-product/remove-all-product`
    addRemoveCompareProd(url);
    saveProdToLocalStorage();
}

export function saveProdToLocalStorage() {

    var pageUrl = `${location.origin}${encodedContextPath}`

    if (get(compareProducts).length > 0) {
        var productSku = get(compareProducts)[get(compareProducts).length - 1].productCode;
        var url = `${pageUrl}/compare-product/add-product?productCode=${productSku}`
        localStorage.setItem(lsKey, JSON.stringify(get(compareProducts)));
        addRemoveCompareProd(url)

    } else {
        localStorage.removeItem(lsKey);
    }
};


export function addRemoveCompareProd(url) {
    fetch(url)
        .then(response => response.json())
        .catch(err => console.log(err))
}

export function parseCashback(cashbackString, countryCode) {
    if (cashbackString && cashbackString.length > 0) {
        const cashbackArray = cashbackString.split('-');
        const cashBackPrice = Number(cashbackArray[0])?.toFixed(currencyDecimal);
        return cashBackPrice;
    }
    return 0;
}

export function parsePriceString(priceString, countryCode) {
    return Number(priceString)?.toFixed(currencyDecimal)
}

export function sortByObjectProperty(array, propertyName, ascending = true) {
    let copy = [...array];

    return (copy as any[]).sort((a, b) => {
        const reverseMux = ascending ? 1 : -1;
        if (a[propertyName] < b[propertyName]) return -1 * reverseMux;
        if (a[propertyName] > b[propertyName]) return +1 * reverseMux;
        return 0;
    });
}
export function refSKUSorter(refSKUArray) {
    return refPropertySorter(refSKUArray, 'productCode');
}
export function refPropertySorter(refSKUArray, propertyName) {
    return function (a, b) {
        return refSKUArray.indexOf(a[propertyName]) - refSKUArray.indexOf(b[propertyName]);
    }
}

export function joinUrls(...urls) {
    return urls.join('/').replace(/\/\//g, '/');
}
export function smartJoinUrls(url1, url2) {
    if (!url2) {
        url2 = '';
    }
    if (url2.startsWith(url1)) return url2;
    return joinUrls(url1, url2);
}

export function joinReadableStrings(strings: string[]) {
    if (strings.length === 0) return '';
    if (strings.length === 1) return strings[0];

    return [strings.slice(0, strings.length - 1).join(', '), strings[strings.length - 1]].join(` ${getStr("extra.pdp.andLable")} `);
}
export function roundDownHalfToFixedPlaces(number, decimalPlaces) {
    const decimals = +decimalPlaces;
    const numberRounded = (number - Math.pow(10, -1 * (decimals + 1))).toFixed(decimalPlaces);

    return numberRounded;
}

export function roundToAtMax2Digits(value) {
    const nExp10 = 100;
    return roundToAtMaxNDigits(value, nExp10);
}

export function roundToNDigits(value, n) {
    return roundToAtMaxNDigits(value, Math.pow(10, +n));
}
export function roundToAtMaxNDigits(value, nExp10) {
    if (value === null || value === undefined) {
        return null;
    }
    return Math.floor(value * nExp10) / nExp10;   //Bugfix for MAVD-14748 (change the method round->floor)
}

export function updateMetaTag(name, content) {
    let tagDOM = document.head.querySelector('meta[name="' + name + '"]')
    if (tagDOM) {
        tagDOM.setAttribute('content', content);
    } else {
        let newMetaTag = document.createElement('meta');
        newMetaTag.name = name;
        newMetaTag.content = content;
        document.head.appendChild(newMetaTag);
    }
}

export function getAttributeString(spllitCharator, stringValue, index) {
    var returnValue = stringValue;
    if (stringValue.indexOf(':') !== -1) {
        var returnValue = stringValue.split(spllitCharator)[index];
    }

    return returnValue;
}

export function createPLPProductTileObject(inputHit: any) {
    return Object.assign(inputHit, {
        ...(!!inputHit?.endDateDiscount && { dealTimer: createDealTimerObject(inputHit?.endDateDiscount) }),

        freegiftInfo: createFreeGiftTileObject(inputHit)
    });
}
export function createFreeGiftTileObject(product: any) {
    if (product?.freegiftworth?.length > 0) {
        var freegiftArray = product.freegiftworth.split('-');
        var freeGiftSet = false;
        var freeGitftWorth = 0;
        var targetQuantity = 0;
        var sourceQuantiy = 0;
        var freeGiftMap = [];
        if (freegiftArray.length === 5) {
            freeGitftWorth = freegiftArray[0];
            targetQuantity = freegiftArray[1];
            sourceQuantiy = freegiftArray[2];
            freeGiftMap = freegiftArray[3];
            freeGiftSet = freegiftArray[4];
        }
        return {
            "hasFreegift": true,
            "freeGiftSet": freeGiftSet,
            "freeGitftWorth": freeGitftWorth,
            "targetQuantity": targetQuantity,
            "sourceQuantiy": sourceQuantiy,
            "freeGiftMap": freeGiftMap
        };
    }

    return { "hasFreegift": false };
}
export function getComingSoonDateFormat(dateString, currentLang) {
    var monthsAR = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];
    var monthsEn = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var returnDateValue = dateString;
    if (dateString.indexOf('-') !== -1) {
        var dateArray = dateString.split('-');
        if (dateArray.length === 3) {
            var dayValue = dateArray[0];
            var monthValue = dateArray[1];
            var monthIndex = parseInt(dateArray[1]) - 1;
            var yearValue = dateArray[2];
            if (currentLang.toLowerCase() === 'en') {
                if (typeof monthsEn[monthIndex] !== 'undefined') {
                    monthValue = monthsEn[monthIndex];
                }
            } else {
                if (typeof monthsAR[monthIndex] !== 'undefined') {
                    monthValue = monthsAR[monthIndex];
                }
            }
            returnDateValue = dayValue + ' ' + monthValue + ',' + ' ' + yearValue;
        }
    }

    return returnDateValue;
}

export function formatNumber(n) {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: currencyDecimal ? Number(currencyDecimal) : null, maximumFractionDigits: currencyDecimal ? Number(currencyDecimal) : null, useGrouping: false }).format(n);
}
export function numberTo2Digits(n) {
    if (n !== undefined && n !== null) {
        const nn = +n; // convert to number, if not already
        return nn >= 0 && n <= 9 ? `0${nn}` : `${nn}`;
    }
    return '';
}
export function getStr(keyOfACCPageLabels, args = []) {

    if (window['ACC'].config.pageLabels) {
        let labelString = (keyOfACCPageLabels in window['ACC'].config.pageLabels) ?
            window['ACC'].config.pageLabels[keyOfACCPageLabels]['label'] :
            keyOfACCPageLabels;

        return args.reduce((str, val, index) => str.replaceAll(`{${index}}`, val), labelString);
    }

    return keyOfACCPageLabels;
}

export function getLabelColor(keyOfACCPageLabels) {
    if (window['ACC'].config.pageLabels) {
        return (keyOfACCPageLabels in window['ACC'].config.pageLabels) ?
            window['ACC'].config.pageLabels[keyOfACCPageLabels]['labelColor'] :
            null;
    }
    return null;
}

export function getBackgroundColor(keyOfACCPageLabels) {
    if (window['ACC'].config.pageLabels) {
        return (keyOfACCPageLabels in window['ACC'].config.pageLabels) ?
            window['ACC'].config.pageLabels[keyOfACCPageLabels]['backgroundColor'] :
            null;
    }
    return null;
}

export function enterKeyEvent(node) {
    const handleInput = (event) => {
        if (event.key === 'Enter') {
            node.dispatchEvent(new CustomEvent('enterKey'));
        }
    };
    node.addEventListener('keypress', handleInput);

    return {
        destroy() {
            node.removeEventListener('keypress', handleInput);
        }
    };
}
export function validityEvent(node) {
    const handleInput = () => {
        if (node.validity.valid) {
            node.dispatchEvent(new CustomEvent('validityChange', { detail: true }));
        } else {
            node.dispatchEvent(new CustomEvent('validityChange', { detail: false }));
        }
    };
    setTimeout(handleInput);
    node.addEventListener('input', handleInput);
    return {
        destroy() {
            node.removeEventListener('input', handleInput);
        }
    };
}

export function checkLocationDisabled() {
    navigator.permissions.query({ name: 'geolocation' })
        .then(async function (result) {
            try {
                if (result.state === 'granted') {
                    islocationDisabled.set(false);
                }
            } catch (error) {
                console.error(error);
            }
        })
        .catch(function (error) {
            console.error('Error checking geolocation permission:', error);
        });
}

export function getDateStringInCurrentLang(dateString, lang) {
    if (lang === 'en') {
        return dateString; // no transformation for english
    }
    dateString = dateString && dateString.trim() || '';
    const d = new Date(dateString);
    if (d instanceof Date && isFinite(d.getDate())) {
        // Thu 13 Jul 2023
        const days = window['ACC'].main.infos.days;
        const months = window['ACC'].main.infos.months;
        const day = days[d.getDay()];
        const date = d.getDate();
        const month = months[d.getMonth()];
        const year = d.getFullYear();
        return `${day} ${date} ${month} ${year}`;
    }
    return ''; // return blank if not a valid date
}

export function isPointWithinElementBounds(mouseClickEvent, targetElement) {
    // returns whether mouse click was done inside targetElement
    const { x, y, width, height } = targetElement.getBoundingClientRect();
    const { x: mouseX, y: mouseY } = mouseClickEvent;

    const xBound = mouseX >= x && mouseX <= x + width;
    const yBound = mouseY >= y && mouseY <= y + height;

    return xBound && yBound;
}

export function getContextedUrl(url: string) {
    if (!url) {
        return '';
    }
    if (url.startsWith('http') || url.startsWith(encodedContextPath)) {
        return url;
    }
    if (url.length > 0 && url[0] !== '/') {
        url = '/' + url;
    }
    return encodedContextPath + url;
}

// usage: <p use:lineEnforcer={{ lines: 2, appendClassOnOverflow: 'class-name-to-add-when-overflow-occurs' }}>Content on which the enforcer will work</p>
export function lineEnforcer(node: HTMLElement, params) {
    // params example: { lines: number }
    const nLines = params?.lines || 5;
    const computedStyles = getComputedStyle(node);
    const strLineHeight = computedStyles.lineHeight;
    const nLineHeight = parseFloat(strLineHeight);
    const nTotalHeight = nLineHeight * nLines;
    const strTotalHeight = strLineHeight.replace(nLineHeight.toString(), nTotalHeight.toString());
    node.style.maxHeight = strTotalHeight;
    node.style.overflowY = 'hidden';

    // append classname if scrollHeight > clientHeight
    if (params.appendClassOnOverflow) {
        const { scrollHeight } = node;
        const isOverflowY = Math.round(scrollHeight / nLineHeight) > nLines;
        if (isOverflowY) {
            node.classList.add(params.appendClassOnOverflow);
        } else {
            node.classList.remove(params.appendClassOnOverflow);
        }
    }
}

export function getTitleCasedString(str, onlyFirstCharacter) {
    if (str?.length > 0) {
        if (onlyFirstCharacter) {
            return str[0].toUpperCase() + str.slice(1).toLowerCase();
        } else {
            return str.split(' ').map(s => s[0].toUpperCase() + s.slice(1).toLowerCase()).join(' ');
        }
    }
    return '';
}

export function reverseChildNodes(node, params) {
    // if reverse is not defined in params, it is assumed true
    if (params.reverse ?? true) {
        const reversedArray = Array.from(node.childNodes.entries()).map(x => x[1]).reverse();
        node.innerHTML = '';
        reversedArray.forEach(r => node.appendChild(r));
    }
}
export function reverseChildNodesForArabicLang(node) {
    const reverse = lang === 'ar';
    return reverseChildNodes(node, { reverse });
}

export type JoodMembershipType = 'NOPRIME' | 'BASICPRIME' | 'VIPPRIME';
export function getJoodMembershipType(): JoodMembershipType {
    const { primeEnabled, primeLevel } = getAppConfig();
    const isPrimeEnabled = primeEnabled == 'true';

    // return value can be one of these strings:
    // NOPRIME (for non-prime users)
    // BASICPRIME (for blue prime users)
    // VIPPRIME (for gold prime users)
    const membershipType = isPrimeEnabled && primeLevel ? primeLevel : 'NOPRIME';
    return membershipType;
}

export function getJoodMembershipBasedPropertyValue(obj, noPrimeProp, basicPrimeProp, vipPrimeProp) {
    const memberType = getJoodMembershipType();
    let targetPropertyName = noPrimeProp;
    if (memberType === 'BASICPRIME' && (basicPrimeProp in obj)) targetPropertyName = basicPrimeProp;
    if (memberType === 'VIPPRIME' && (vipPrimeProp in obj)) targetPropertyName = vipPrimeProp;

    return obj[targetPropertyName];
}

export function applyRTLArrowCss(parent, prevLeftCss, prevRightCss, nxtLeftCss, nxtRightCss) {
    if ((document.dir === 'rtl') && parent && parent.length > 0) {
        parent.forEach(function (section) {
            const nextArrow = section.querySelector('.splide__arrow--next');
            const prvArrow = section.querySelector('.splide__arrow--prev');
            if (prvArrow) {
                prvArrow.style.setProperty('left', prevLeftCss, 'important');
                prvArrow.style.setProperty('right', prevRightCss, 'important');
            }
            if (nextArrow) {
                nextArrow.style.setProperty('left', nxtLeftCss, 'important');
                nextArrow.style.setProperty('right', nxtRightCss, 'important');
            }
        })
    }
}

export function isValueSponsoredOrExtraSponsored(value) {
    return sponsoredKey.length > 0
        && extraSponsoredKey.length > 0
        && typeof value === "string"
        && [sponsoredKey, extraSponsoredKey].indexOf(value) > -1;
}
export function isValueExtraSponsored(value) {
    return isValueSponsoredOrExtraSponsored(value) && value === extraSponsoredKey;
}
export function isValueSponsored(value) {
    return isValueSponsoredOrExtraSponsored(value) && value === sponsoredKey;
}

export function slideOpenAnimation(node) {
    return slide(node, { axis: "y", easing: expoInOut, duration: 250 });
}

export function getPriceHTMLOrFree(value) {
    if (+value > 0) {
        if (lang === 'en') {
            return `${currency} <strong>${Number(value)?.toFixed(currencyDecimal)}</strong>`
        } else {
            return `<strong>${Number(value)?.toFixed(currencyDecimal)}</strong> ${currency}`
        }
    }
    return `<strong>${getStr('text.free')}</strong>`
}

export function convertJsonObjectToFormData(formJson, keyPrefix = '') {
    let output = [];
    for (const [key, value] of Object.entries(formJson)) {
        if (Array.isArray(value)) {
            if (value.length > 0 && typeof value[0] === "object" && value[0] !== null) {
                output = [...output, ...value.map((v, i) => convertJsonObjectToFormData(v, `${keyPrefix}${key}[${i}].`))];


            } else {
                output.push(`${key}=${value.join(',')}`);
            }
        } else if (typeof value === "object" && value !== null) {
            output = [...output, convertJsonObjectToFormData(value, `${keyPrefix}${key}.`)];
        } else if (value === undefined || value === null) {
            // do nothing
            output.push(`${keyPrefix}${key}=`);
        } else {
            output.push(`${keyPrefix}${key}=${value}`);
        }
    }
    return output.join('&');
}

export function mergeObjectsBySimilarProperties(objectArray) {
    return objectArray.reduce((a, b) => {
        if (!a) return Object.assign({}, b);
        const c = {};
        for (let key in a) {
            if (key in b) {
                c[key] = [a[key], b[key]].join(', ');
            }
        }
        return c;

    }, null);
}
export function getProductDataForGTM(prdData, index) {
    return {
        nameEn: prdData?.nameEn,
        productCode: prdData?.code || prdData?.productCode,
        brandEn: prdData?.brandEn,
        allCategoriesEn: prdData?.allCategoriesEn,
        priceValueDiscount: prdData?.priceValueDiscount || (prdData?.discountPrice && prdData?.discountPrice.value),
        aftreCashBackPrice: prdData?.aftreCashBackPrice || (prdData?.aftreCashBackPrice && prdData?.aftreCashBackPrice.value),
        allPromotions: prdData?.allPromotions,
        price: prdData?.price,
        wasPrice: prdData?.wasPrice || (prdData?.price && prdData?.price.value),
        variantsEn: prdData?.variantsEn,
        productIndex: index ? index : 0,
        pageCategoryNameEn: prdData.pageCategoryNameEn,
        pageSubCategoryNameEn: prdData.pageSubCategoryNameEn,
        rmsCategoryNameEn: prdData.rmsCategoryNameEn,
        productUrl: prdData?.productUrl,
        urlEn: prdData?.urlEn ? prdData?.urlEn : prdData?.url,
        urlAr: prdData?.urlAr ? prdData?.urlAr : prdData?.url
    }
}

export function sortByPosition(a, b) {
    return a.position - b.position;
}
export function unxGetCountsFromFacetValues(values: any[]): string[] {
    return values?.filter((v, i) => i % 2 !== 0) || [];
}
export function unxGetKeysFromFacetValues(values: any[]): string[] {
    return values?.filter((v, i) => i % 2 === 0) || [];
}
export function unxValuesToObjectArray(values: any[], facetName = null) {
    // input [ 'val1', count1, 'val2', count2 ]
    // output [ { value: 'val1', count: count1 }, { value: 'val2', count: count2 }]

    if (values?.length > 1) {
        let output = [];
        for (let i = 0; i < values.length - 1; i += 2) {
            output.push({
                value: values[i],
                count: values[i + 1]
            });
        }

        const currentCityCode = userSelectedCityCode || userDefaultCityCode;
        if (["deliveryFacet_uFilter", "inStockCities_uFilter"].indexOf(facetName) > -1) {
            output = output.filter(x => x.value.startsWith(currentCityCode) || x.value === "codEnabled");
        } else if (facetName === 'inStock_uFilter') {
            const cityStockString = `${currentCityCode}_inStock`;
            output = output.filter(x => x.value === cityStockString);
        } else if (facetName === 'offersFacet_uFilter') {
            const goingOutOfStockSuffix = '_goingOutOfStock';
            const acceptedGoingOOSValue = `${currentCityCode}${goingOutOfStockSuffix}`;
            output = output.filter(x => (!x.value.endsWith(goingOutOfStockSuffix) || x.value === acceptedGoingOOSValue));
        }
        return output;
    }
    return [];
}
export function algoliaToUnbxdSortString(algoliaSortString: string) {
    if (algoliaSortString === "relevance") return '';
    return algoliaSortString?.replace(/_/g, ' ').trim() || '';
}

export function composeURLFromUnbxdResponseQueryParams(searchQueryParamsFromUnbxdResponse: any): URL {
    const { filter, page = 0, q = '', rows = 24, sort = 'relevance' } = searchQueryParamsFromUnbxdResponse;
    const url = new URL(document.location.pathname, document.location.origin);

    if (!!filter) {
        const filterArr = Array.isArray(filter) ? [...filter] : [filter];
        const _q = composeQFromUnbxdFilterArray(filterArr, q);
        url.searchParams.set('q', _q);
    }
    if (q !== undefined) {
        url.searchParams.set('text', q);

        if (url.pathname.includes('/c/')) {
            const seoUrl = generateSEOUrlForCategoryUrl(url.pathname, url.searchParams.get('q'));
            if (seoUrl?.length > 0) {
                url.pathname = url.pathname.split('/facet/')[0] + seoUrl;
            }
        }
    }
    if (page !== undefined) {
        url.searchParams.set('pg', page);
    }
    if (rows !== undefined) {
        url.searchParams.set('pageSize', rows);
    }
    if (sort !== undefined) {
        if (['', 'relevance'].includes(sort)) {
            url.searchParams.set('sort', 'relevance');
        } else {
            let sortValue = sort;
            if (sortValue.includes(',')) {
                sortValue = sortValue.split(',')[1].trim();
            }
            const selectedSortObject = (<any[]>get(unxSortOptions)).find(x => x.sortSuffix === sortValue);
            url.searchParams.set('sort', selectedSortObject.code);
        }
    }
    return url;
}
export function replaceSpecialCharactersInURL(targetURL: string, restoreToOriginal: boolean = false) {
    let output = decodeURIComponent(targetURL);
    if (output.includes(':"')) {
        output = output.replaceAll('"', '');
    }
    const specialCharacterMap = {
        '"': '$inch',
        '°': '$degree',
        '&': '$and',
        '-': '$dash',
        ',': '$comma',
        '+': '$plus',
        '²': '$square',
        ';': '$semicolon',
        '\'': '$apostrophe',
        '/': '__',
    };

    const replaceSources = restoreToOriginal ? Object.values(specialCharacterMap) : Object.keys(specialCharacterMap);
    const replaceTargets = restoreToOriginal ? Object.keys(specialCharacterMap) : Object.values(specialCharacterMap);

    for (let i = 0; i < replaceSources.length; i++) {
        if (restoreToOriginal) {
            replaceTargets[i] = encodeURIComponent(replaceTargets[i]);
        }
        output = (<any>output).replaceAll(replaceSources[i], replaceTargets[i]);
    }

    return output;
}
export function generateSEOUrlForCategoryUrl(pathname, qParamString) {
    let wip: string = qParamString;
    if (wip?.startsWith(':relevance')) wip = wip?.substring(10);
    if (wip?.startsWith(':')) wip = wip?.substring(1);

    const b = wip?.split(':');
    const c = [];
    for (let i = 0; i < b?.length - 1; i += 2) {
        if (filtersTobeExcludedInURL.includes(b[i])) {
            continue;
        } else {
            c.push(b[i]);
            c.push(b[i + 1]);
        }
    }
    wip = c.join(':');
    wip = replaceSpecialCharactersInURL(wip); // wip.replace(/["°&-,+²;/']/g, m => charsToReplace[m]);
    wip = `/facet/${wip}`;

    return wip;
}
export function parseUnbxdResponseQueryParamsToSearchParams(searchQueryParamsFromUnbxdResponse: any): URLSearchParams {
    const url = composeURLFromUnbxdResponseQueryParams(searchQueryParamsFromUnbxdResponse);
    return parseUrlToUnbxdSP(url.href);
}
export function composeUrlFromUnbxdResponseQueryParams(searchQueryParamsFromUnbxdResponse: any) {
    return composeURLFromUnbxdResponseQueryParams(searchQueryParamsFromUnbxdResponse).href;
}
export function parseUrlToUnbxdSP(url: string): URLSearchParams {
    url = replaceSpecialCharactersInURL(url, true);
    const isRelativeUrl = !url.startsWith('http');
    const urlObject = isRelativeUrl
        ? new URL(url, 'https://www.extra.com')
        : new URL(url);

    const text = urlObject.searchParams.get('text');
    let q = urlObject.searchParams.get('q');
    if (!q && urlObject.pathname.includes('/facet/')) {
        q = decodeURIComponent(urlObject.pathname.split('/facet/')[1]);
    }
    const pageSize = urlObject.searchParams.get('pageSize');
    const pg = urlObject.searchParams.get('pg');
    let sort = urlObject.searchParams.get('sort');
    if (!sort) sort = 'relevance';
    const selectedSortObject = (<any[]>get(unxSortOptions)).find(x => x.code === sort);

    const unbxdParams: any = {
        ...(!!text && { q: text }),
        ...(!!pageSize && { rows: +pageSize }),
        ...(!!pg && { page: +pg }),
        ...(!!selectedSortObject.sortSuffix && { sort: selectedSortObject.sortSuffix })
    };
    const unxParams = new URLSearchParams(unbxdParams);
    const qParsedSP = parseQToUnbxdSearchParams(q, text);

    for (const [key, value] of qParsedSP.entries()) {
        unxParams.append(key, value);
    }

    // for category urls, append filter=categories:"<code>"
    if (url.startsWith('c/')) url = `/{url}`;
    if (url.indexOf('/c/') > -1) {
        const { category } = getAppConfig();
        if (category?.ruleName?.length > 0) {
            const { ruleName } = category;
            unxParams.append('q', ruleName);
            unxParams.append('facet', 'true');

        } else {
            const categoryCode = urlObject.pathname.split('/c/')[1].split('/')[0];
            if (!!categoryCode) {
                unxParams.append('p', `categories_uFilter:"${categoryCode}"`);
                unxParams.append('pagetype', 'boolean');
                unxParams.append('facet', 'true');
            }
        }
    }

    return unxParams;
}

export function combineSPByOverriding(sp1: URLSearchParams, sp2: URLSearchParams) {
    const targetSP = new URLSearchParams(sp1);
    for (const [k, v] of sp2) {
        if (k === 'filter') {
            // combine filter
            const filterName = v.substring(0, v.indexOf(':'));
            const existing = targetSP.getAll('filter').find(x => x.startsWith(filterName));
            if (existing) {
                // @ts-ignore
                targetSP.delete('filter', existing);
            }
            targetSP.append('filter', v);

        } else {
            targetSP.set(k, v);
        }
    }
    return targetSP;
}

export function parseQToUnbxdSearchParams(q: string, text: string): URLSearchParams {
    const parsed = parseQ(q, text);
    const sp = new URLSearchParams();

    for (const [key, value] of Object.entries(parsed)) {
        const filterKey = 'filter';
        let filterValue = singleUnbxdFacetToFilterString(key, <any[]>value);
        sp.append(filterKey, filterValue);
    }
    return sp;
}
export function singleUnbxdFacetToFilterString(facetName: string, values: string[]): string {
    if (facetName === 'price') return `${facetName}:[${values[0].replace('-', ' TO ').replace(/[\[\]]/g, '')}]`;
    else if (facetName === 'ratingFacet') {
        // coming from parseUrl
        const minRatingSelected = Math.min(...values.map(v => +v));
        if (get(unxSelectedRatings) === null) {
            unxSelectedRatings.setSingleRatingStatus(minRatingSelected, true);
        }
        return `rating:[${minRatingSelected} TO 5]`;
    } else if (facetName === 'rating') {
        // coming from unbxd params
        return values[0].startsWith('[') ? `rating:${values[0]}` : values[0];
    } else if (facetName === 'inStock_uFilter') {
        const currentCity = userSelectedCityCode || userDefaultCityCode;
        // const value = `inStockCities:"${currentCity}" OR sellingOutFastCities:"${currentCity}"`;
        const value = `inStock_uFilter:${currentCity}_inStock`;
        return value;
    }
    else return values?.map(v => typeof (v) === "string" && v?.includes(' ') ? `${facetName}:"${unxEscapeSpecialCharacters(v)}"` : `${facetName}:${unxEscapeSpecialCharacters(v)}`).join(' OR ');
}
function parseQ(q: string, text: string) {
    let output = {};
    if (!q) return output;

    const splits = q.split(':').filter(x => x?.length > 0);
    const unwantedStarts = ['', text, 'relevance'];
    for (const word of unwantedStarts) {
        if (splits[0] === word) splits.splice(0, 1);
    }

    for (let i = 0; i < splits.length - 1; i += 2) {
        let key = splits[i];
        let value = splits[i + 1];

        if (['price', 'ratingFacet', 'type', 'restockableCities'].indexOf(key) === -1) {
            key += '_uFilter';
        }
        if (key in output) output[key].push(value);
        else output[key] = [value];
    }
    return output;
}
function composeQFromUnbxdFilterArray(filterArray: any[], searchTerm: string): string {
    const filterStringified = filterArray.map((f: string) => {
        let temp = f.replace(/ OR /g, ':')
            .replace(/(_uFilter|\[|\]|\\)/g, '')
            .replace(/ TO /, '-')
            .replace(/rating:(\d)-\d/, "ratingFacet:$1");
        if (temp.startsWith('inStock')) {
            temp = 'inStock:true';
        }
        temp = replaceSpecialCharactersInURL(temp);
        return temp;
    }).join(':');

    return `${searchTerm}:relevance:${filterStringified}`;
}
export function combineURLSearchParams(searchParamArray: URLSearchParams[]) {
    const targetSP = new URLSearchParams();
    for (let sp of searchParamArray) {
        for (let [spKey, spValue] of sp.entries()) {
            if (spKey === "filter") targetSP.append(spKey, spValue);
            else targetSP.set(spKey, spValue);
        }
    }
    return targetSP;
}
export function getLabelForFacetValue(value, facetName) {
    if (facetName === 'price') return value.replace(/\[(\d+) TO (\d+)\]/, `$1 - $2 ${currency}`);
    if (facetName === 'rating') return value.replace(/\[(\d) TO \d\]/, `$1 ${getStr('facet.range.up')}`);
    if (facetName === 'deliveryFacet_uFilter') {
        // filterInfoMap
        if ((<string>value)?.endsWith('_homeDeliveryEnabled')) return filterInfoMap['deliveryFacetHomeDelivery'][0].name;
        else if ((<string>value)?.endsWith('_collectFromStoreEnabled')) return filterInfoMap['deliveryFacetCollectFromStore'][0].name;
        else if (value === 'codEnabled') return filterInfoMap['deliveryFacetCodEnabled'][0].name;
    }
    if (facetName === 'inStockCities_uFilter') {
        return getStr('text.quote.yes.button.label');
    }
    if (facetName === 'inStock_uFilter') {
        return filterInfoMap['availabilityFacetInStock'][0].name;
    }
    if (facetName === 'offersFacet_uFilter') {
        if ((value as string).endsWith('_goingOutOfStock')) return filterInfoMap['offersFacetGoingOutOfStock'][0].name;
        const filterInfoString = `facet.productStatus.${value}`;
        if (filterInfoString in filterInfoMap) {
            return filterInfoMap[filterInfoString][0].name;
        } else {
            return value;
        }
    }
    if (value === true) return getStr("text.quote.yes.button.label");
    if (value === false) return getStr("text.quote.no.button.label");

    return value;
}
export function gtmViewListEvent(productdData) {
    if (productdData.length > 0) {
        window['ACC'].extragtm.click.trackViewList("", "", productdData);
    }
}
export function jsonBooleanReviver(_: string, value: any) {
    if (value === "true") return true;
    if (value === "false") return false;
    return value;
}

export function onClickWishlist(data, productCode) {

    data.detail.event.preventDefault();
    data.detail.event.stopPropagation();

    const hideLoader = () => window["ACC"].loader.hideLoader();
    const handleError = (err) => console.log("error: " + err);
    const updateWishlistCount = (wishlistSize) => {
        const wishlistCountElement = document.querySelector('.js-wishlist-count');
        if (wishlistCountElement) {
            wishlistCountElement.innerHTML = wishlistSize;
        }
    };

    const handleResponse = (res) => {
        if (!res.success) {
            window.location.assign(res.redirectUrl);
        } else {
            getCartLoginDetails();
            updateWishlistCount(res.wishlistSize);
        }
    };

    const addToWishlistAsync = async () => {
        try {
            const res = await addToWishlist(productCode);
            handleResponse(res);
        } catch (err) {
            handleError(err);
        } finally {
            hideLoader();
        }
    };

    addToWishlistAsync();
}

export function removeHTMLScrollbar(remove: boolean = true) {
    document
        .querySelector('html')
        .style
        .overflow = remove ? 'hidden' : 'auto';
}
export function isRunningUnderAppWebView() {
    return typeof window.ACC.main.appdetected !== "undefined";
}

// This method will take care of OOS, Restockable, Jood day and other normal tags
// Other duplicate methods will be removed
export function getProductTags(product, isAddToCartPopupTags = false) {
    let outputTags = [];
    const cityCode = userSelectedCityCode || userDefaultCityCode;

    const isComingSoon = !!product?.comingSoon;
    const isInStock = !isComingSoon && (('inStockCities' in product) ? product?.inStockCities?.includes(cityCode) : (product?.stock?.stockLevelStatus === "inStock" || product?.stock?.stockLevelStatus?.code === "inStock"));
    const isSellingOutFast = !isInStock && (('sellingOutFastCities' in product) ? product?.sellingOutFastCities?.includes(cityCode) : (product?.stock?.stockLevelStatus === "lowStock" || product?.stock?.stockLevelStatus?.code === "lowStock"));
    const isProductInStock = isInStock || isSellingOutFast;
    const isRestockable = !isProductInStock && product?.restockableCities?.includes(cityCode);
    const restockableTagString: string = product[getLangSpecificFieldname('restockableTag')] || null;

    if (isRestockable && !!restockableTagString) {
        const restockableTag = parseSingleTagFromString(restockableTagString);
        outputTags.push(restockableTag);
    } else if (!isProductInStock && !isComingSoon && !isAddToCartPopupTags) {
        const { outOfStock } = getAppConfig();
        outputTags.push({ label: outOfStock, theme: 'gray', 'borderRadLBot': 12, 'borderRadRBot': 12 });
    } else if (product && product[getLangSpecificFieldname("productStatus", lang) + 'List']?.length > 0) {
        outputTags = product[getLangSpecificFieldname("productStatus", lang) + 'List']
            .map(tagString => parseSingleTagFromString(tagString));
    }

    // prime tags
    if (isProductInStock && !isRestockable) {
        if (product?.tacticalBasicPromo || product?.tacticalVipPromo) {
            if (primeLevel === 'BASICPRIME') {
                outputTags.unshift({ label: getStr('jood.day.offer.text'), bgImage: getStr('prime.jood.day.bg.BASIC'), textColorCode: getStr('prime.jood.day.color.BASIC'), bgRepeat: 'no-repeat' });
            } else if (primeLevel === 'VIPPRIME') {
                outputTags.unshift({ label: getStr('jood.day.offer.text'), bgImage: getStr('prime.jood.day.bg.VIP'), textColorCode: getStr('prime.jood.day.color.VIP'), bgRepeat: 'no-repeat' });
            } else {
                outputTags.unshift({ label: getStr('jood.day.offer.text'), bgImage: getStr('prime.jood.day.bg.NONPRIME'), textColorCode: getStr('prime.jood.day.color.NONPRIME'), bgRepeat: 'round' });
            }
        }
    }

    return outputTags;
}

// example input: "Restocking Soon|RE_STOCKABLE|false|#000000|10||#FFFFFF"
export function parseSingleTagFromString(tagString: string) {
    if (tagString?.length > 0) {
        const [label, productStatus, copyToClipBoardAllowed, backgroundColorCode, , iconURL, textColorCode] = tagString.split('|');

        return {
            label,
            productStatus,
            copyToClipBoardAllowed: copyToClipBoardAllowed == 'true',
            backgroundColorCode,
            iconURL,
            textColorCode
        };
    }

    return null;
}

export function roundToPreviousN(val, n) {
    if (val % n === 0) return val;
    return val - (val % n);
}
export function roundToNextN(val, n) {
    if (val % n === 0) return val;
    return val + (n - val % n);
}
export function dividePriceRangeIntoNParts(min, max, n) {
    let range = max - min;
    let size = range / n;
    // [0-25, 25-50, 50-75, 75-100]
    if (range % n !== 0) {
        // need to adjust the numbers
        min = roundToPreviousN(min, 5);
        max = roundToNextN(max, 5);
        range = max - min;
        size = Math.ceil(range / n);
    }
    return Array(n)
        .fill(min)
        .map((x, i) => x + i * size)
        .map(x => [x, x + size]);
}

/**
 * Adds a SKU to the cart using QAC and handles the response.
 *
 * @param {Object} detail - The details required for adding the SKU to the cart.
 * @param {string} detail.sku - The SKU of the product to add to the cart.
 * @param {boolean} detail.silentAdd - Indicates whether the add operation should be silent (no UI feedback).
 * 
 * @returns {Promise<void>} A promise that resolves when the add operation is complete.
 *
 * This function initiates the process of adding a product to the cart and checks
 * if the product was successfully added. If successful, it updates the cart 
 * and handles any UI feedback. If there is an error, it displays an error message.
 */
export function manageAtcQacInSvelte({ detail: { sku, silentAdd } }) {
    // Initiates the process to add a SKU to the cart via QAC.
    return window['ACC'].qac._innerAddSKUToCart(sku, silentAdd).then(async (response) => {
        window.ACC.loader.showLoader();
        // If there's no response, exit the function.
        if (!response) return;
        // Destructure relevant properties from the response.
        const { productAdded, errorMsg, errorMessage } = response;
        const error = errorMsg || errorMessage;
        // Determine if the product was successfully added to the cart.
        const isProductAdded = (svelteATCEnabled && productAdded) ||
            (!svelteATCEnabled && response.cartData.productAdded && !!response.addToCartLayer);
        // If the product was added, handle the cart response and fetch the updated cart.
        if (isProductAdded) {
            try {
                const resp: any = await getProductsFromAlgoliaAsync([sku], OPTION_RESPONSE_FIELDS(["response"]));
                if (resp?.hits?.length === 0) {
                    throw new Error('Empty response from Algolia');
                } else {
                    response['productDataForGtm'] = resp?.hits[0];
                }
            } catch (error) {
                console.error(error);
            }
            await handleCartResponse(response);
            await window['ACC'].qac.getQacCartResponse(silentAdd);
            window.ACC.loader.hideLoader();

        } else {
            window.ACC.loader.hideLoader();
            // If there was an error, show a snackbar with the error message.
            window.ACC.main.showCouponCodeSnackbar('', false, getStr(error));
        }
    });
}
/**
 * Handles the global QAC event by adding and removing the event listener for the QAC open event.
 *
 * This function ensures that only one instance of the event listener is active at any time.
 * It first removes any existing listener and then adds a new one to handle the QAC open event.
 */
export function handleGlobalQacHandler() {
    // Define the event name for better readability
    const eventName = window.ACC.qac.var.QAC_OPEN_EVENT_NAME;
    // Remove any existing event listeners to prevent duplicate handlers
    window.removeEventListener(eventName, manageAtcQacInSvelte);

    // Add the event listener to handle the QAC open event
    window.addEventListener(eventName, manageAtcQacInSvelte);
}

/**
 * Handles the global event for opening the restockable popup.
 *
 * This function ensures that only one instance of the event listener is active at any time.
 * It first removes any existing listener and then adds a new one to handle the restockable open event.
 */
export function handleGlobalRestockableHandler() {
    // Define the event name for better readability
    const eventName = window.ACC.qac.var.QAC_OPEN_RESTOCKABLE_POPUP_EVENT_NAME;

    // Remove any existing event listeners to prevent duplicate handlers
    window.removeEventListener(eventName, manageRestockableDialogInSvelte);

    // Add the event listener to handle the QAC open event
    window.addEventListener(eventName, manageRestockableDialogInSvelte);
}

/**
 * Handles the global event for opening the restockable popup.
 *
 * This function is the event listener for the global event "extra:openRestockablePopup".
 * It will be called whenever the event is triggered.
 *
 * @param {{ detail: { product: object } }} event - The event object.
 * @param {object} event.detail.product - The product object that needs to be shown in the popup.
 */
export function manageRestockableDialogInSvelte({ detail: { product } }) {
    // Show the popup with the product information
    restockableProductContent.set(product);
    openRestockablePopup.set(true);
}

export function unxUpdateTopAndBottomSeoContent() {
    if (unbxdEnabled) {
        const { category } = getAppConfig();
        if (!!category) {
            isCategoryStaticTopContent.set(!!category?.top_Seo_Content);
            categoryStaticTopContent.set({
                heading: category?.top_Seo_Content_Title,
                body: category?.top_Seo_Content,
            });

            isCategoryStaticBottomContent.set(
                !!category?.bottom_Seo_Content,
            );
            categoryStaticBottomContent.set({
                heading: category?.bottom_Seo_Content_Title,
                body: category?.bottom_Seo_Content,
            });
        } else {
            isCategoryStaticTopContent.set(false);
            isCategoryStaticBottomContent.set(false);
        }
    }
}