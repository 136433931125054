import { derived, Readable, writable } from 'svelte/store';
import { getAppConfig } from '../../../common/config/app-config';
import { mergeObjectsBySimilarProperties } from '../../../common/util';


export const pdpData = writable({ name: "" });
export const showAddToCartPopup = writable(false);
export const productImages = writable([]);
export const showPdpTileInfo = writable(false); // todo: remove this var
export const showPdpMoreInfoModal = writable(false);
export const pdpMoreInfoActiveTab = writable('');
export const giftCardAmount = writable(0);
export const tasheelPrice = writable(0);

export const productDetails = writable<any>(null);
export const pageLabels = writable([]);
export const currentLocation = writable({});
export const nearestPickupStore = writable(null);
export const showHighlightDetailsPopup = writable(false);
export const hightlightDetailsClickOnSlideIndex = writable(0);
export const selectedGifts = writable([]);
export const selectedGiftGroup = writable(-1);
export const selectedFrequentProds = writable([]);
export const giftPopupForCart = writable(false);
export const shouldPDPGiftOptionReset = writable(false);
export const giftSelectedForCart = writable(false);
export const addToCartContent = writable(null);
export const snackbarLabel = writable('');
export const openSnackbar = writable(false);
export const openExtraDrawer = writable(false);
export const currentSelectedStoreData = writable(null);
export const tasheelConsumerInfo = writable(null);
export const openAddToCartDrawer = writable(false);
export const rsaBrand = writable('');
export const rsaYear = writable('');
export const rsaPlateNo = writable<string>('');
export const selectedServices = writable([]);
export const qacProductCode = writable(null);
export const imgMagnifier = writable({
    imgHeight: 0,
    imgTop: 0,
    imgLeft: 0,
    imgWidth: 0,
    clientX: 0,
    clientY: 0,
    clientWidth: 0,
    clientHeight: 0
});
export const imageHoverStart = writable(false);
export const currentProductImage = writable('');
export const activateImageZoom = writable(false);
export const giftvalidemail = writable(false);
export const giftcardemail = writable('');
export const giftcardmsg = writable('');
export const isReviewAvailable = writable(false);
export const prodReviewFlag = writable(true);
export const customerReviews = writable([]);
export const extraReviews = writable([]);
export const expertReviews = writable([]);
export const totalReviewsMixPanel = writable(5);
export const ratingMixPanel = writable(4);
export const frequentlyBoughtProducts = writable([]);
export const frequentlyBoughtAlternativeProducts = writable([]);
export const alreadyFetchedBundleData = writable([]);
export const altBundleObj = writable({});
export const tabbyPrice = writable(0);
export const pdpSplitAcServiceSelectedItem = writable(null);
export const pdpSafeguardServiceSelectedItem = writable(null);
export const pdpSetupServiceSelectedItem = writable(null);
// mixpanel related stores
export const pdpSafeguardServiceMixpanelData = writable(null);
export const pdpSplitAcServiceMixpanelData = writable(null);
export const pdpSetupServiceMixpanelData = writable(null);
export const pdpAppleCarePlusServiceSelectedItem = writable(null);
export const qacAtcPopupFlag = writable(false);
export const openRestockablePopup = writable(false);
export const restockableProductContent = writable(null);


export function setMixpanelDataForService(service_name, service_plan_name, service_addon_name, service_fees, service_id) {
    let targetStore = null;
    if (service_name === "Safeguard") {
        targetStore = pdpSafeguardServiceMixpanelData;
    } else if (service_name === "Setup") {
        targetStore = pdpSetupServiceMixpanelData;
    } else if (service_name === "Split AC") {
        targetStore = pdpSplitAcServiceMixpanelData;
    }
    if (targetStore) {
        if (service_plan_name === undefined) targetStore.set(null);
        else targetStore.set({ service_name, service_plan_name, service_addon_name, service_fees, service_id });
    }
}

// Derived stores for PDP
export const pdpProductPrice = derived(productDetails, ($pd, set) => {
    set({
        price: $pd?.discountPrice?.value || $pd?.price?.value,
        basicPrimePrice: $pd?.productBasicPrimeData?.primePrice?.value || 0,
        vipPrimePrice: $pd?.productVipPrimeData?.primePrice?.value || 0,
        wasPrice: $pd?.price?.value,
        priceValueTasheel: $pd?.priceValueTasheel?.value,
        eligibleForTamara: !!$pd?.tamaraDetails,
        eligibleForTabby: $pd?.eligibleForTabby,
        expectedPrimeSaving: $pd?.productVipPrimeData?.expectedPrimeSaving,
        tacticalBasicPromo: !!$pd?.productBasicPrimeData?.tacticalPromo,
        tacticalVipPromo: !!$pd?.productVipPrimeData?.tacticalPromo,
        tacticalVipPrimePrice: $pd?.productVipPrimeData?.tacticalPrice?.value || 0,
        tacticalBasicPrimePrice: $pd?.productBasicPrimeData?.tacticalPrice?.value || 0,
        hasCashBack: !!$pd?.hasCashBack,
        cashback: $pd?.cashBackPrice,
        priceValueDiscountPercentageFlag: !!$pd?.percentageDiscount,
        isComingSoon: $pd?.isComingSoon,
        isDigitalGiftCard: $pd?.isDigitalGiftCard
    });
}, null);

export const pdpGiftCardPriceData = derived(productDetails, ($pd, set) => {
    set({
        tasheelImageUrl: $pd?.tasheelImageUrl,
        tahsheelPaymentAttributes: $pd?.tahsheelPaymentAttributes,
        digitalGiftcardType: $pd?.digitalGiftcardType,
        attributeLabels: $pd?.attributeLabels,
        giftCardPriceDenominations: $pd?.giftCardPriceDenominations,
        minGiftCardAmount: $pd?.minGiftCardAmount,
        maxGiftCardAmount: $pd?.maxGiftCardAmount
    });
});

export const pdpInstalmentCardData = derived(productDetails, ($pd, set) => {
    const {
        bnplTabbyPaymentModeEnabled,
        bnplTamaraPaymentModeEnabled,
        bnplBaseetaPaymentModeEnabled,
    } = getAppConfig();
    const obj = {
        baseeta: null,
        tamara: null,
        tabby: null,
        easyPaymentDetails: null
    };

    if ($pd?.tamaraDetails && bnplTamaraPaymentModeEnabled) {
        obj.tamara = {
            ...$pd.tamaraDetails
        };
    }
    if ($pd?.priceValueTasheel && bnplBaseetaPaymentModeEnabled) {
        obj.baseeta = {
            priceValue: $pd?.priceValueTasheel?.value,
            tasheelInstallmentMonths: window['ACC'].config.algoliaConfig.tasheelInfo.tasheelInstallmentMonths,
            tasheelContentUrl: window['ACC'].config.algoliaConfig.tasheelInfo.tasheelContentUrl
        };
    }
    if ($pd?.tabbyDetails && bnplTabbyPaymentModeEnabled) {
        obj.tabby = {
            ...$pd?.tabbyDetails
        };
    }
    if ($pd?.easyPaymentDetails) {
        obj.easyPaymentDetails = {
            ...$pd?.easyPaymentDetails
        };
    }

    set(obj);
}, {
    baseeta: null,
    tamara: null,
    tabby: null,
    easyPaymentDetails: null
});

export const pdpOfferCardData = derived(productDetails, ($pd, set) => {
    let allValidPromotions = [];
    if ($pd?.bankPromotionDetails?.length > 0) {
        allValidPromotions = $pd.bankPromotionDetails.filter(x => x?.bankHtmlMessage?.length > 0);
    }
    const obj = {
        freeGiftDataList: $pd?.freeGiftNotAvailable ? ($pd?.freeGiftDataList?.length === 1 && $pd?.freeGiftDataList?.acPriceDiscountValue !== null ? $pd?.freeGiftDataList : $pd?.freeGiftNotAvailableMessage) : $pd?.freeGiftDataList?.length > 0 ? $pd?.freeGiftDataList : null,
        couponData: $pd?.couponData,
        bankPromotionDetails: allValidPromotions.length > 0 ? allValidPromotions : null
    };
    set(obj);
}, {
    freeGiftDataList: null,
    couponData: null,
    bankPromotionDetails: null
});

export const pdpDeliveryOptionCardData = derived(productDetails, ($pd, set) => {
    const isTransactional = window['ACC'].config.isTransactional;

    set({
        onDemandDeliveryMode: $pd?.onDemandDeliveryMode,
        stock: $pd?.stock,
        isTransactional,
        productStatus: $pd?.productStatus,
        isCollectFromStoreEnabled: $pd?.isCollectFromStoreEnabled,
        cfsPosPickUpInStoreData: $pd?.cfsPosPickUpInStoreData,
        homeDeliveryData: $pd?.homeDeliveryData,
        deliveryLeadTimeNow: $pd?.deliveryLeadTimeNow,
        deliveryLeadTimeBeforeBy: $pd?.deliveryLeadTimeBeforeBy,
        displayFreeDelivery: $pd?.displayFreeDelivery,
        deliveryPayment: $pd?.deliveryPayment,
        expressDeliveryMode: $pd?.expressDeliveryMode,
        code: $pd?.code,
        isHomeDeliveryEnabled: $pd?.isHomeDeliveryEnabled,
        shippingClassification: $pd?.shippingClassification,
        standardDeliveryCost: $pd?.standardDeliveryCost,
        deliveryLeadTimeBefore: $pd?.deliveryLeadTimeBefore,
        deliveryLeadMessage: $pd?.deliveryLeadMessage,
        isDigitalGiftCard: $pd?.isDigitalGiftCard,
        digitalDeliveryItem: $pd?.digitalDeliveryItem
    });
});

export const pdpProductExtraLinksData = derived(productDetails, ($pd, set) => {
    const isSKUInStock = $pd?.stock?.stockLevelStatus?.code !== 'outOfStock';

    set({
        isSKUInStock,
        productIconLabelContents: $pd?.productIconLabelContents,
        warranty: $pd?.warranty,
        warrantyDuration: $pd?.warrantyDuration
    });
});

export const pdpServiceCardData = derived(productDetails, ($pd, set) => {
    set({
        eligibleVasEntries: $pd?.eligibleVasEntries?.length ? $pd?.eligibleVasEntries.filter((vasEntry) => vasEntry.vasGroup !== "ACC") : [],
        servicesBlocks: $pd?.servicesBlocks,
        lmdConfigurations: $pd?.lmdConfigurations,
        appleCarePlusVasEntries: $pd?.eligibleVasEntries?.length ? $pd?.eligibleVasEntries.filter((vasEntry) => vasEntry.vasGroup === "ACC") : [],
    });
});

export const pdpServicesData: Readable<any> = derived(
    [pdpSplitAcServiceSelectedItem, pdpSafeguardServiceSelectedItem, pdpSetupServiceSelectedItem, pdpAppleCarePlusServiceSelectedItem],
    ($services, set) => {
        set($services.filter(s => !!s));
    }
);

export const pdpCashbackCardData = derived(productDetails, ($pd, set) => {
    set({
        aftreCashBackPrice: $pd?.aftreCashBackPrice?.value,
        cashBackPrice: $pd?.cashBackPrice
    });
});

export const pdpServicesMixpanelData = derived(
    // order of items defines the priority of services
    [pdpSafeguardServiceMixpanelData, pdpSetupServiceMixpanelData, pdpSplitAcServiceMixpanelData],
    ($serviceMPData, set) => {
        const nonNullServiceMPData = $serviceMPData.filter(x => !!x);
        if (nonNullServiceMPData.length > 0) {
            const mergedServiceMPData = mergeObjectsBySimilarProperties(nonNullServiceMPData);
            set(mergedServiceMPData);
        } else {
            set(null);
        }
    }
);

export const pdpServicesGtmData = derived(
    // order of items defines the priority of services
    [pdpSafeguardServiceMixpanelData, pdpSetupServiceMixpanelData, pdpSplitAcServiceMixpanelData],
    ($serviceMPData, set) => {
        const nonNullServiceMPData = $serviceMPData.filter(x => !!x);
        if (nonNullServiceMPData.length > 0) {
            set(nonNullServiceMPData);
        } else {
            set(null);
        }
    }
);