<script lang="ts" context="module">
    import {
        getServiceBlockForCode,
        getServiceProductCodes,
        jsVasDetailsClickHandler,
    } from "./atc-utils";
    import { getAppConfig } from "../../../../common/config/app-config";
    import {
        getContextedUrl,
        getJoodMembershipType,
    } from "../../../../common/util";
    import { getStr } from "../../../../common/util";

    const { extraThemeResourcePath, primeEnabled, lang, currency } =
        getAppConfig();
    const membershipType = getJoodMembershipType();
    const addonLabel = getStr("extra.pdp.service.addon");
    const addonName = getStr("extra.pdp.service.accidental.warranty.text");
    const imageBaseUrl = `${extraThemeResourcePath}/images`;
    const extra_service_store_device = `${imageBaseUrl}/extra_service_store_device.svg`;
    const extra_service_unpack_device = `${imageBaseUrl}/extra_service_unpack_device.svg`;
</script>

<script lang="ts">
    import AtcFormatPrice from "./AtcFormatPrice.svelte";
    import AtcJoodVasPrimeDetails from "./AtcJoodVasPrimeDetails.svelte";
    import AtcJoodVasService from "./AtcJoodVasService.svelte";

    export let entry;
    export let selectedServices;
    export let product;
    export let parentEntryNumber;
    export let loopindex;

    let servicePrimeCount = 0;
    let servicesBlockContent = "";
    let servicesBlockImage = "";
    let servicesBlockLabel = "";
    let servicesBlockLink = "";
    let noPlanVasOptionSelected = false;

    $: esstentialclass = computEesstentialclass(entry);
    $: if (product?.servicesBlocks?.length > 0) {
        ({
            servicesBlockContent,
            servicesBlockImage,
            servicesBlockLabel,
            servicesBlockLink,
        } = getServiceBlockForCode(
            product.servicesBlocks,
            "servicesSafeguardPDPContent",
        ));
    }

    function computEesstentialclass(entry) {
        if (!entry.combinedVas || entry.vasPrices.length < 5) {
            return "";
        }
        if (entry.vasPrices.length > 6) {
            return "c-essential-plus more-estentail-plus";
        }
        return "c-essential-plus";
    }
    function getCombinedVasDetails(selectedServices, vasPrice) {
        let selectedCombinedServiceGroupNumber = null;
        let selectedCombinedEntrynumber = null;

        if (selectedServices?.length > 0) {
            const combinedVasOptionSelected = !!selectedServices?.find(
                (selectedService) =>
                    selectedService.vasType === vasPrice.vasType.code &&
                    selectedService.priceType === vasPrice.priceType,
            )
                ? "selected"
                : "";
            noPlanVasOptionSelected = !!selectedServices?.find(
                (selectedService) =>
                    selectedService.vasType === "noPlan" &&
                    selectedService.priceType === "NO_PLAN_PRICE",
            );
            const temp = selectedServices.find(
                (selectedService) =>
                    entry.vasGroup === selectedService.vasGroup,
            );
            if (!!temp) {
                selectedCombinedServiceGroupNumber =
                    temp.serviceGroupNumber || null;
                selectedCombinedEntrynumber = temp.entryNumber;
            }
            return {
                combinedVasOptionSelected,
                noPlanVasOptionSelected,
                selectedCombinedServiceGroupNumber,
                selectedCombinedEntrynumber,
            };
        }

        return {
            combinedVasOptionSelected: "",
            noPlanVasOptionSelected: false,
            selectedCombinedServiceGroupNumber,
            selectedCombinedEntrynumber,
        };
    }
    function incrementServicePrimeCount() {
        servicePrimeCount += 1;
        return "";
    }

    function getVasPriceDetails(selectedServices, vasPrice) {
        let combinedVasOptionSelected = null,
            combinedVasAddonSelected = null,
            selectedServiceVasType = null,
            selectedCombinedAddOnEntrynumber = null,
            selectedCombinedAddOnServiceGroupNumber = null;

        if (selectedServices?.length > 0) {
            const selectedService = selectedServices.find(
                (ss) =>
                    ss.vasType === vasPrice.vasType.code &&
                    ss.priceType === vasPrice.priceType,
            );
            if (!!selectedService) {
                combinedVasOptionSelected = "selected";
                selectedServiceVasType = selectedService.vasType;

                if (
                    selectedService.combinedVas &&
                    vasPrice.additionalAwsProductCode?.length > 0
                ) {
                    combinedVasAddonSelected = "selected";
                }

                if (entry.vasGroup === selectedService.vasGroup) {
                    selectedCombinedAddOnEntrynumber =
                        selectedService.entryNumber;
                    selectedCombinedAddOnServiceGroupNumber =
                        selectedService.serviceGroupNumber;
                }
            }
        }

        const output = {
            combinedVasOptionSelected,
            combinedVasAddonSelected,
            selectedServiceVasType,
            selectedCombinedAddOnEntrynumber,
            selectedCombinedAddOnServiceGroupNumber,
        };

        return output;
    }

    function getVasDetails(selectedServices, vasPrice) {
        let vasOptionSelected = null,
            selectedEntrynumber = null;

        if (selectedServices?.length > 0) {
            noPlanVasOptionSelected = !!selectedServices.find(
                (selectedService) =>
                    selectedService.vasType === "noPlan" &&
                    selectedService.priceType === "NO_PLAN_PRICE",
            );
            vasOptionSelected = !!selectedServices.find(
                (selectedService) =>
                    selectedService.vasType === vasPrice.vasType.code &&
                    selectedService.priceType === vasPrice.priceType,
            )
                ? "selected"
                : "";
            const t = selectedServices.find(
                (selectedService) =>
                    entry.vasGroup === selectedService.vasGroup,
            );
            if (!!t) {
                selectedEntrynumber = t.entryNumber;
            }
        }

        return {
            vasOptionSelected,
            noPlanVasOptionSelected,
            selectedEntrynumber,
        };
    }
</script>

<div class="row-flex">
    <div class="col-flex-xs-1 col-flex-md-1">
        <img alt="Extrasafeguard" src={servicesBlockImage} />
    </div>
    <div class="col-flex-xs-4 col-flex-md-3 c_extra-service-label">
        {servicesBlockLabel}
    </div>
    <div class="col-flex-xs-12 col-flex-md-12 c_extra-vas-content">
        <span class="">
            {servicesBlockContent}
        </span>
    </div>
    <div class="col-flex-xs-12 col-flex-md-12 c_extra-vas-link">
        <a href={servicesBlockLink} target="_blank">
            {getStr("extra.vas.service.learn.more")}
        </a>
    </div>

    {#if entry.combinedVas}
        <div
            style="flex-wrap: nowrap;"
            class="{membershipType} col-flex-xs-12 col-flex-md-12 vas-details-wrapper c_vas-details-items js-extraSetup-button-active-remove c-vas-safecaurd"
        >
            {#each entry.vasPrices as vasPrice, vasPriceIndex}
                {@const {
                    combinedVasOptionSelected,
                    noPlanVasOptionSelected,
                    selectedCombinedServiceGroupNumber,
                    selectedCombinedEntrynumber,
                } = getCombinedVasDetails(selectedServices, vasPrice)}
                {@const serviceProductCodes = getServiceProductCodes(
                    vasPrice,
                    entry,
                )}
                {#if vasPrice.additionalAwsProductCode === null && vasPrice.additionalAwsVasGroup === null}
                    <button
                        class="{membershipType} c_vas-details-list add-to-cart-service data-index-{vasPriceIndex} {esstentialclass} {vasPriceIndex} js-vas-details-svelte"
                        on:click={jsVasDetailsClickHandler}
                        class:no-plan={vasPrice.noPlanOption}
                        class:selected={vasPrice.defaultWarranty ||
                            combinedVasOptionSelected?.length > 0}
                        data-item-add={combinedVasOptionSelected === "selected"
                            ? "true"
                            : "false"}
                        data-type={entry.vasGroup}
                        data-service="safeguard"
                        data-additional-aws-productCode={vasPrice.additionalAwsProductCode}
                        data-source="Cart Popup"
                        data-plan={vasPrice.vasType.label}
                        data-serviceid={vasPriceIndex + 1}
                        data-addon="no addon"
                        data-noplanenabled={entry.noPlanIncluded}
                        data-price="{vasPrice?.servicePrice
                            ?.formattedValue} {currency}"
                        data-vasType={vasPrice.vasType.code}
                        data-productcode={product.code}
                        data-id={vasPriceIndex}
                        data-entryNumber="extra-vas-{parentEntryNumber}"
                    >
                        <div class="col-md-12">
                            {#if primeEnabled}
                                <div class="c_vas-details-jood-container">
                                    {#if membershipType !== "NOPRIME"}
                                        {#if membershipType === "BASICPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                            {incrementServicePrimeCount()}
                                        {/if}
                                        {#if membershipType === "VIPPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                            <AtcJoodVasPrimeDetails
                                                joodType="VIP"
                                                vasLabel={vasPrice.vasType
                                                    .label}
                                                oldVasPrice={vasPrice
                                                    ?.servicePrice
                                                    ?.formattedValue}
                                                vasPrice={vasPrice
                                                    ?.vipPrimeDiscountPrice
                                                    ?.formattedValue}
                                            />
                                        {:else if membershipType === "BASICPRIME" && vasPrice.basicPrimeDiscountPrice.value > 0}
                                            <AtcJoodVasPrimeDetails
                                                joodType="BASIC"
                                                vasLabel={vasPrice.vasType
                                                    .label}
                                                oldVasPrice={vasPrice
                                                    ?.servicePrice
                                                    ?.formattedValue}
                                                vasPrice={vasPrice
                                                    ?.basicPrimeDiscountPrice
                                                    ?.formattedValue}
                                            />
                                        {:else}
                                            <AtcJoodVasPrimeDetails
                                                joodType="BASIC"
                                                vasLabel={vasPrice.vasType
                                                    .label}
                                                oldVasPrice={vasPrice
                                                    ?.servicePrice
                                                    ?.formattedValue}
                                                vasPrice={vasPrice
                                                    ?.basicPrimeDiscountPrice
                                                    ?.formattedValue}
                                            />
                                        {/if}
                                    {:else}
                                        {#if vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                            {incrementServicePrimeCount()}
                                        {/if}
                                        <section>
                                            <div
                                                class="c_vas-details-list-year c-vas-safecaurd"
                                            >
                                                {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                                    <span
                                                        class="c_product-details-title-reduction"
                                                    >
                                                        {#if lang === "en"}
                                                            {vasPrice
                                                                .percentageDiscount
                                                                .value}%&nbsp;<span
                                                                >{getStr(
                                                                    "product.pourcentageDiscount.off",
                                                                )}</span
                                                            >
                                                        {:else}
                                                            %{vasPrice
                                                                .percentageDiscount
                                                                .value}&nbsp;<span
                                                                >{getStr(
                                                                    "product.pourcentageDiscount.off",
                                                                )}</span
                                                            >
                                                        {/if}
                                                    </span>
                                                {/if}
                                                {vasPrice.vasType.label}
                                            </div>
                                            <div
                                                class="vas-name"
                                                class:hide={vasPrice.noPlanOption}
                                            >
                                                <span
                                                    >{vasPrice.vasType
                                                        .name}</span
                                                >
                                            </div>
                                        </section>
                                        {#if vasPrice.discountedPrice !== null}
                                            <div
                                                class="c_product-price-prices"
                                                class:hide={vasPrice.noPlanOption}
                                            >
                                                <div
                                                    class="c_product-price-was-is"
                                                >
                                                    <span
                                                        class="c_product-price-previous"
                                                        >{vasPrice.servicePrice
                                                            .value}
                                                    </span>
                                                </div>
                                                <div
                                                    class="c_product-price-current"
                                                >
                                                    <AtcFormatPrice
                                                        priceData={vasPrice.discountedPrice}
                                                        displayFreeForZero={true}
                                                        addCurrency={true}
                                                    />
                                                </div>
                                            </div>
                                        {:else}
                                            <div
                                                class="c_vas-details-list-price"
                                                class:hide={vasPrice.noPlanOption}
                                            >
                                                <AtcFormatPrice
                                                    priceData={vasPrice.servicePrice}
                                                    displayFreeForZero={true}
                                                    addCurrency={true}
                                                />
                                            </div>
                                        {/if}
                                    {/if}
                                </div>
                            {:else}
                                <section>
                                    <div
                                        class="c_vas-details-list-year c-vas-safecaurd"
                                    >
                                        {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                            <span
                                                class="c_product-details-title-reduction"
                                            >
                                                {#if lang === "en"}
                                                    {vasPrice.percentageDiscount
                                                        .value}%&nbsp;<span
                                                        >{getStr(
                                                            "product.pourcentageDiscount.off",
                                                        )}</span
                                                    >
                                                {:else}
                                                    %{vasPrice
                                                        .percentageDiscount
                                                        .value}&nbsp;<span
                                                        >{getStr(
                                                            "product.pourcentageDiscount.off",
                                                        )}</span
                                                    >
                                                {/if}
                                            </span>
                                        {/if}
                                        {vasPrice.vasType.label}
                                    </div>
                                    <div
                                        class="vas-name"
                                        class:hide={vasPrice.noPlanOption}
                                    >
                                        <span>{vasPrice.vasType.name}</span>
                                    </div>
                                </section>
                                {#if vasPrice.discountedPrice !== null}
                                    <div
                                        class="c_product-price-prices"
                                        class:hide={vasPrice.noPlanOption}
                                    >
                                        <div class="c_product-price-was-is">
                                            <span
                                                class="c_product-price-previous"
                                                >{vasPrice.servicePrice.value}
                                            </span>
                                        </div>
                                        <div class="c_product-price-current">
                                            <AtcFormatPrice
                                                priceData={vasPrice.discountedPrice}
                                                displayFreeForZero={true}
                                                addCurrency={true}
                                            />
                                        </div>
                                    </div>
                                {:else}
                                    <div
                                        class="c_vas-details-list-price"
                                        class:hide={vasPrice.noPlanOption}
                                    >
                                        <AtcFormatPrice
                                            priceData={vasPrice.servicePrice}
                                            displayFreeForZero={true}
                                            addCurrency={true}
                                        />
                                    </div>
                                {/if}
                            {/if}

                            {#each serviceProductCodes as code}
                                {@const freeGiftData =
                                    vasPrice.serviceFreeGiftMap[code] || null}
                                {#if !!freeGiftData}
                                    {@const sourceQty =
                                        freeGiftData.freeGiftQtuantities[
                                            "sourceQty"
                                        ]}
                                    {@const targetQty =
                                        freeGiftData.freeGiftQtuantities[
                                            "targetQty"
                                        ]}
                                    <div
                                        class="c_gift-items js-freegiftexist"
                                        class:js-freegiff-set-fixed={sourceQty ===
                                            targetQty}
                                        class:c_gift-items-fixed={sourceQty ===
                                            targetQty}
                                    >
                                        <div class="c_gift-items-title">
                                            {#if sourceQty < targetQty}
                                                <span
                                                    class="c_gift-items-title--color"
                                                >
                                                    {getStr(
                                                        "product.promotion.freegifts.display.lower.txt",
                                                        [sourceQty],
                                                    )}</span
                                                >
                                            {:else}
                                                <span
                                                    class="c_gift-items-title--color"
                                                >
                                                    {getStr(
                                                        "text.fixed.freegift.count.display.txt.vas",
                                                        [sourceQty],
                                                    )}
                                                </span>&nbsp;{getStr(
                                                    "product.promotion.freegifts.item.worth.txt",
                                                )}&nbsp;
                                                <span
                                                    class="c_gift-items-title--color mobile-font"
                                                    >{freeGiftData.freeGiftWorth}
                                                    {currency}</span
                                                >
                                            {/if}
                                        </div>
                                    </div>
                                {/if}
                            {/each}
                        </div>
                        <div class="hide">
                            <div class="c_vas-details-list-button">
                                <form
                                    id="vasAddToCartForm{product.code}{vasPriceIndex}-{loopindex}"
                                    action={getContextedUrl("/cart/vas/add")}
                                    modelAttribute="vasAddtocartForm"
                                    method="post"
                                    class="c_form js-formValidation js-vas-addtocart-form"
                                    data-ajax="true"
                                    data-submit="vasaddtocart"
                                >
                                    <div
                                        id="test_vasAddToCartButton_$"
                                        style="display:inline"
                                    >
                                        {#if vasPrice.noPlanOption}
                                            <input
                                                type="hidden"
                                                name="vasProductCode"
                                                value={entry.noPlanProductCode}
                                            />
                                            <input
                                                type="hidden"
                                                name="noPlanOption"
                                                value={vasPrice.noPlanOption}
                                            />
                                        {:else}
                                            <input
                                                type="hidden"
                                                name="vasProductCode"
                                                value={vasPrice?.productCodes
                                                    ?.length > 0
                                                    ? vasPrice.productCodes
                                                    : entry.serviceProductCode}
                                            />
                                        {/if}
                                        <input
                                            type="hidden"
                                            name="vasType"
                                            value={vasPrice.vasType.code}
                                        />
                                        <input
                                            type="hidden"
                                            name="priceType"
                                            value={vasPrice.priceType}
                                        />
                                        <input
                                            type="hidden"
                                            name="priceTo"
                                            value={vasPrice.priceTo}
                                        />
                                        <input
                                            type="hidden"
                                            name="productCode"
                                            value={product.code}
                                        />
                                        <input
                                            type="hidden"
                                            name="additionalAwsProductCode"
                                            value={vasPrice.additionalAwsProductCode}
                                        />
                                        <input
                                            type="hidden"
                                            name="additionalAwsVasGroup"
                                            value={vasPrice.additionalAwsVasGroup}
                                        />
                                        <input
                                            type="hidden"
                                            name="combinedVas"
                                            value={entry.combinedVas}
                                        />
                                        <input
                                            type="hidden"
                                            name="parentEntryNumber"
                                            value={parentEntryNumber}
                                        />
                                        <input
                                            type="hidden"
                                            name="qty"
                                            value="1"
                                        />
                                        <input
                                            type="hidden"
                                            name="serviceGroupNumber"
                                            value={typeof selectedCombinedServiceGroupNumber ===
                                            "number"
                                                ? selectedCombinedServiceGroupNumber
                                                : ""}
                                        />
                                        {#each serviceProductCodes as code}
                                            {@const freeGiftData =
                                                vasPrice.serviceFreeGiftMap[
                                                    code
                                                ] || null}
                                            {#if !!freeGiftData}
                                                {#each freeGiftData.freeGiftProducts as freeGift}
                                                    <input
                                                        type="hidden"
                                                        name="freeGiftCodesMap[{code}]"
                                                        value={freeGift.code}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="freeGiftRuleIdMap[{code}]"
                                                        value={freeGiftData.freeGiftRuleId}
                                                    />
                                                {/each}
                                            {/if}
                                        {/each}
                                        <input
                                            type="hidden"
                                            name="vasGroup"
                                            value={entry.vasGroup}
                                        />
                                        <input
                                            type="hidden"
                                            name="showApplyService"
                                            value={entry.showApplyService}
                                        />
                                        <input
                                            type="hidden"
                                            name="vasEntryShowService"
                                            value={entry.showApplyService}
                                        />
                                        <input
                                            type="hidden"
                                            name="entryNumber"
                                            value={typeof selectedCombinedEntrynumber ===
                                            "number"
                                                ? selectedCombinedEntrynumber
                                                : ""}
                                            id="vasEntryNumber"
                                            class="js-vasEntryNumber"
                                        />
                                        <button
                                            type="button"
                                            id="vas-button-{product.code}{vasPriceIndex}"
                                            aria-labelledby="vas-button-{product.code}{vasPriceIndex} vasAddToCartForm{product.code}{vasPriceIndex}"
                                            class="c_vas-details-list-button-vas"
                                            class:selected={vasPrice.defaultWarranty}
                                        >
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </button>
                {/if}
            {/each}
        </div>
        <div
            style="flex-wrap: wrap;"
            class="col-flex-xs-12 col-flex-md-12 c_combined-vas vas-details-wrapper c_vas-details-items js-extraSetup-button-active-remove c-vas-safecaurd"
        >
            {#each entry.vasPrices as vasPrice, vasPriceIndex}
                {@const {
                    combinedVasOptionSelected,
                    combinedVasAddonSelected,
                    selectedServiceVasType,
                    selectedCombinedAddOnEntrynumber,
                    selectedCombinedAddOnServiceGroupNumber,
                } = getVasPriceDetails(selectedServices, vasPrice)}
                {@const serviceProductCodes = getServiceProductCodes(
                    vasPrice,
                    entry,
                )}
                {#if !!vasPrice.additionalAwsProductCode && !!vasPrice.additionalAwsVasGroup}
                    {#if vasPrice.vasType?.code === vasPrice.vasType?.code || combinedVasOptionSelected === "selected"}
                        {@const hideShowAddOn =
                            (selectedServiceVasType === vasPrice.vasType.code &&
                                !vasPrice.noPlanOption) ||
                            combinedVasOptionSelected === "selected"
                                ? "show"
                                : "hide"}
                        <div
                            class="c_add-ons-msg {hideShowAddOn}"
                            data-vasType={vasPrice.vasType.code}
                        >
                            <div>
                                <span class="green-text">
                                    {getStr("extra.pdp.service.smartchoice")}
                                </span>
                                {getStr("extra.pdp.service.dontmissout")}
                            </div>
                            <span class="addon-subheading">
                                {getStr(
                                    "extra.pdp.service.gobeyondmanufacturer",
                                )}
                            </span>
                        </div>
                        <button
                            class="c_vas-details-list add-to-cart-addon-service js-addons data-index-{vasPriceIndex} {esstentialclass} {vasPriceIndex} js-vas-details-svelte"
                            on:click={jsVasDetailsClickHandler}
                            class:no-plan={vasPrice?.noPlanOption}
                            class:hide={selectedServiceVasType !==
                                vasPrice.vasType.code}
                            class:selected={vasPrice.defaultWarranty ||
                                combinedVasAddonSelected === "selected"}
                            data-item-add={combinedVasAddonSelected ===
                                "selected"}
                            data-type={entry.vasGroup}
                            data-vasType={vasPrice.vasType.code}
                            data-source="Cart Popup"
                            data-plan={vasPrice.vasType.label}
                            data-noplanenabled={entry.noPlanIncluded}
                            data-serviceid={vasPriceIndex + 1}
                            data-addon="Accidental warranty"
                            data-price="{vasPrice?.servicePrice
                                ?.formattedValue} {currency}"
                            data-productcode={product.code}
                            data-id={vasPriceIndex}
                            data-entryNumber="extra-vas-{parentEntryNumber}"
                        >
                            <div class="col-md-12">
                                {#if primeEnabled}
                                    <div class="c_vas-details-jood-container">
                                        {#if membershipType !== "NOPRIME"}
                                            {#if membershipType === "BASICPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                                {incrementServicePrimeCount()}
                                            {/if}
                                            {#if membershipType === "VIPPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                                <AtcJoodVasPrimeDetails
                                                    joodType="VIP"
                                                    vasLabel={addonLabel}
                                                    vasName={addonName}
                                                    vasPrice={vasPrice
                                                        ?.servicePrice
                                                        ?.formattedValue}
                                                />
                                            {:else if membershipType === "BASICPRIME" && vasPrice.basicPrimeDiscountPrice.value > 0}
                                                <AtcJoodVasPrimeDetails
                                                    joodType="BASIC"
                                                    vasLabel={addonLabel}
                                                    vasName={addonName}
                                                    vasPrice={vasPrice
                                                        ?.servicePrice
                                                        ?.formattedValue}
                                                />
                                            {:else}
                                                <AtcJoodVasPrimeDetails
                                                    joodType="BASIC"
                                                    vasLabel={addonLabel}
                                                    vasName={addonName}
                                                    vasPrice={vasPrice
                                                        ?.servicePrice
                                                        ?.formattedValue}
                                                />
                                            {/if}
                                        {:else}
                                            {#if vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                                {incrementServicePrimeCount()}
                                            {/if}
                                            <section>
                                                <div
                                                    class="c_vas-details-list-year c-vas-safecaurd"
                                                >
                                                    {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                                        <span
                                                            class="c_product-details-title-reduction"
                                                        >
                                                            {#if lang === "en"}
                                                                {vasPrice
                                                                    .percentageDiscount
                                                                    .value}%&nbsp;<span
                                                                    >{getStr(
                                                                        "product.pourcentageDiscount.off",
                                                                    )}</span
                                                                >
                                                            {:else}
                                                                %{vasPrice
                                                                    .percentageDiscount
                                                                    .value}&nbsp;<span
                                                                    >{getStr(
                                                                        "product.pourcentageDiscount.off",
                                                                    )}</span
                                                                >
                                                            {/if}
                                                        </span>
                                                    {/if}
                                                    {getStr(
                                                        "extra.pdp.service.addon",
                                                    )}
                                                </div>
                                                <div
                                                    class="vas-name"
                                                    class:hide={vasPrice.noPlanOption}
                                                >
                                                    <span
                                                        >{getStr(
                                                            "extra.pdp.service.accidental.warranty.text",
                                                        )} ({vasPrice.vasType
                                                            .name})</span
                                                    >
                                                </div>
                                            </section>
                                            {#if vasPrice?.discountedPrice !== null}
                                                <div
                                                    class="c_product-price-prices"
                                                    class:hide={vasPrice.noPlanOption}
                                                >
                                                    <div
                                                        class="c_product-price-was-is"
                                                    >
                                                        <span
                                                            class="c_product-price-previous"
                                                            >{vasPrice
                                                                .servicePrice
                                                                .value}</span
                                                        >
                                                    </div>
                                                    <div
                                                        class="c_product-price-current"
                                                    >
                                                        <AtcFormatPrice
                                                            priceData={vasPrice.discountedPrice}
                                                            displayFreeForZero={true}
                                                            addCurrency={true}
                                                        />
                                                    </div>
                                                </div>
                                            {:else}
                                                <div
                                                    class="c_vas-details-list-price"
                                                    class:hide={vasPrice.noPlanOption}
                                                >
                                                    <AtcFormatPrice
                                                        priceData={vasPrice.servicePrice}
                                                        displayFreeForZero={true}
                                                        addCurrency={true}
                                                    />
                                                </div>
                                            {/if}
                                        {/if}
                                    </div>
                                {:else}
                                    <section>
                                        <div
                                            class="c_vas-details-list-year c-vas-safecaurd"
                                        >
                                            {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                                <span
                                                    class="c_product-details-title-reduction"
                                                >
                                                    {#if lang === "en"}
                                                        {vasPrice
                                                            .percentageDiscount
                                                            .value}%&nbsp;<span
                                                            >{getStr(
                                                                "product.pourcentageDiscount.off",
                                                            )}</span
                                                        >
                                                    {:else}
                                                        %{vasPrice
                                                            .percentageDiscount
                                                            .value}&nbsp;<span
                                                            >{getStr(
                                                                "product.pourcentageDiscount.off",
                                                            )}</span
                                                        >
                                                    {/if}
                                                </span>
                                            {/if}
                                            {vasPrice.vasType.label}
                                        </div>
                                        <div
                                            class="vas-name"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <span>{vasPrice.vasType.name}</span>
                                        </div>
                                    </section>
                                    {#if vasPrice.discountedPrice !== null}
                                        <div
                                            class="c_product-price-prices"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <div class="c_product-price-was-is">
                                                <span
                                                    class="c_product-price-previous"
                                                    >{vasPrice.servicePrice
                                                        .value}</span
                                                >
                                            </div>
                                            <div
                                                class="c_product-price-current"
                                            >
                                                <AtcFormatPrice
                                                    priceData={vasPrice.discountedPrice}
                                                    displayFreeForZero={true}
                                                    addCurrency={true}
                                                />
                                            </div>
                                        </div>
                                    {:else}
                                        <div
                                            class="c_vas-details-list-price"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <AtcFormatPrice
                                                priceData={vasPrice.servicePrice}
                                                displayFreeForZero={true}
                                                addCurrency={true}
                                            />
                                        </div>
                                    {/if}
                                {/if}

                                {#each serviceProductCodes as code}
                                    {@const freeGiftData =
                                        vasPrice.serviceFreeGiftMap[code]}
                                    {#if !!freeGiftData}
                                        {@const sourceQty =
                                            freeGiftData.freeGiftQtuantities[
                                                "sourceQty"
                                            ]}
                                        {@const targetQty =
                                            freeGiftData.freeGiftQtuantities[
                                                "targetQty"
                                            ]}
                                        <div
                                            class="c_gift-items js-freegiftexist"
                                            class:js-freegiff-set-fixed={sourceQty ===
                                                targetQty}
                                            class:c_gift-items-fixed={sourceQty ===
                                                targetQty}
                                        >
                                            <div class="c_gift-items-title">
                                                {#if sourceQty < targetQty}
                                                    <span
                                                        class="c_gift-items-title--color"
                                                    >
                                                        {getStr(
                                                            "product.promotion.freegifts.display.lower.txt",
                                                            [sourceQty],
                                                        )}
                                                    </span>
                                                {:else}
                                                    <span
                                                        class="c_gift-items-title--color"
                                                    >
                                                        {getStr(
                                                            "text.fixed.freegift.count.display.txt.vas",
                                                            [sourceQty],
                                                        )}
                                                    </span>&nbsp;{getStr(
                                                        "product.promotion.freegifts.item.worth.txt",
                                                    )}&nbsp;
                                                    <span
                                                        class="c_gift-items-title--color mobile-font"
                                                        >{freeGiftData.freeGiftWorth}
                                                        {currency}</span
                                                    >
                                                {/if}
                                            </div>
                                        </div>
                                    {/if}
                                {/each}
                            </div>
                            <div class="hide">
                                <div class="c_vas-details-list-button">
                                    <form
                                        id="vasAddToCartForm{product.code}{vasPriceIndex}-{loopindex}"
                                        action={getContextedUrl(
                                            "/cart/vas/add",
                                        )}
                                        modelAttribute="vasAddtocartForm"
                                        method="post"
                                        class="c_form js-formValidation js-vas-addtocart-form"
                                        data-ajax="true"
                                        data-submit="vasaddtocart"
                                    >
                                        <div
                                            id="test_vasAddToCartButton_$"
                                            style="display:inline"
                                        >
                                            {#if vasPrice.noPlanOption}
                                                <input
                                                    type="hidden"
                                                    name="vasProductCode"
                                                    value={entry.noPlanProductCode}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="noPlanOption"
                                                    value={vasPrice.noPlanOption}
                                                />
                                            {:else}
                                                <input
                                                    type="hidden"
                                                    name="vasProductCode"
                                                    value={vasPrice.productCodes
                                                        ?.length > 0
                                                        ? vasPrice.productCodes
                                                        : entry.serviceProductCode}
                                                />
                                            {/if}
                                            <input
                                                type="hidden"
                                                name="vasType"
                                                value={vasPrice.vasType.code}
                                            />
                                            <input
                                                type="hidden"
                                                name="priceType"
                                                value={vasPrice.priceType}
                                            />
                                            <input
                                                type="hidden"
                                                name="priceTo"
                                                value={vasPrice.priceTo}
                                            />
                                            <input
                                                type="hidden"
                                                name="productCode"
                                                value={product.code}
                                            />
                                            <input
                                                type="hidden"
                                                name="additionalAwsProductCode"
                                                value={vasPrice.additionalAwsProductCode}
                                            />
                                            <input
                                                type="hidden"
                                                name="additionalAwsVasGroup"
                                                value={vasPrice.additionalAwsVasGroup}
                                            />
                                            <input
                                                type="hidden"
                                                name="combinedVas"
                                                value={entry.combinedVas}
                                            />
                                            <input
                                                type="hidden"
                                                name="parentEntryNumber"
                                                value={parentEntryNumber}
                                            />
                                            <input
                                                type="hidden"
                                                name="qty"
                                                value="1"
                                            />
                                            <input
                                                type="hidden"
                                                name="serviceGroupNumber"
                                                value={selectedCombinedAddOnServiceGroupNumber
                                                    ? selectedCombinedAddOnServiceGroupNumber
                                                    : ""}
                                            />
                                            {#each serviceProductCodes as code}
                                                {@const freeGiftData =
                                                    vasPrice.serviceFreeGiftMap[
                                                        code
                                                    ]}
                                                {#if !!freeGiftData}
                                                    {#each freeGiftData.freeGiftProducts as freeGift}
                                                        <input
                                                            type="hidden"
                                                            name="freeGiftCodesMap[{code}]"
                                                            value={freeGift.code}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="freeGiftRuleIdMap[{code}]"
                                                            value={freeGiftData.freeGiftRuleId}
                                                        />
                                                    {/each}
                                                {/if}
                                            {/each}
                                            <input
                                                type="hidden"
                                                name="vasGroup"
                                                value={entry.vasGroup}
                                            />
                                            <input
                                                type="hidden"
                                                name="showApplyService"
                                                value={entry.showApplyService}
                                            />
                                            <input
                                                type="hidden"
                                                name="vasEntryShowService"
                                                value={entry.showApplyService}
                                            />
                                            <input
                                                type="hidden"
                                                name="entryNumber"
                                                value={!!selectedCombinedAddOnEntrynumber
                                                    ? selectedCombinedAddOnEntrynumber
                                                    : ""}
                                                id="vasEntryNumber"
                                                class="js-vasEntryNumber"
                                            />
                                            <button
                                                type="button"
                                                id="vas-button-{product.code}{vasPriceIndex}"
                                                aria-labelledby="vas-button-{product.code}{vasPriceIndex} vasAddToCartForm{product.code}{vasPriceIndex}"
                                                data-vasType={vasPrice.vasType
                                                    .code}
                                                data-serviceProductCode={entry.serviceProductCode}
                                                class="c_vas-details-list-button-vas"
                                                class:selected={vasPrice.defaultWarranty}
                                            >
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </button>
                    {/if}
                {/if}
            {/each}
        </div>
    {:else}
        <div
            style="flex-wrap: nowrap;"
            class="{membershipType} js-vas-group-addtocart-{entry.vasGroup} col-flex-xs-12 col-flex-md-12 vas-details-wrapper c_vas-details-items js-extraSetup-button-active-remove c-vas-safecaurd"
        >
            {#each entry.vasPrices as vasPrice, vasPriceIndex}
                {@const serviceProductCodes = getServiceProductCodes(
                    vasPrice,
                    entry,
                )}
                {@const {
                    vasOptionSelected,
                    noPlanVasOptionSelected,
                    selectedEntrynumber,
                } = getVasDetails(selectedServices, vasPrice)}
                <button
                    class="{membershipType}  c_vas-details-list add-to-cart-service data-index-{vasPriceIndex} {esstentialclass} {vasPriceIndex} js-vas-details-svelte"
                    on:click={jsVasDetailsClickHandler}
                    class:no-plan={vasPrice?.noPlanOption}
                    class:selected={vasPrice.defaultWarranty ||
                        vasOptionSelected?.length > 0}
                    data-item-add={vasOptionSelected === "selected"}
                    data-type={entry.vasGroup}
                    data-source="Cart Popup"
                    data-service="safeguard"
                    data-plan={vasPrice.vasType.label}
                    data-noplanenabled={entry.noPlanIncluded}
                    data-vasType={vasPrice.vasType.code}
                    data-serviceid={vasPriceIndex + 1}
                    data-addon="no addon"
                    data-price="{vasPrice?.servicePrice
                        ?.formattedValue} {currency}"
                    data-productcode={product.code}
                    data-id={vasPriceIndex}
                    data-entryNumber="extra-vas-{parentEntryNumber}"
                >
                    <div>
                        {#if primeEnabled}
                            <div class="c_vas-details-jood-container">
                                {#if membershipType !== "NOPRIME"}
                                    {#if membershipType === "BASICPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                        {incrementServicePrimeCount()}
                                    {/if}

                                    {#if membershipType === "VIPPRIME" && vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                        <AtcJoodVasPrimeDetails
                                            joodType="VIP"
                                            vasLabel={vasPrice.vasType.label}
                                            oldVasPrice={vasPrice?.servicePrice
                                                ?.formattedValue}
                                            vasPrice={vasPrice
                                                ?.vipPrimeDiscountPrice
                                                ?.formattedValue}
                                        />
                                    {:else if membershipType === "BASICPRIME" && vasPrice?.basicPrimeDiscountPrice?.value > 0}
                                        <AtcJoodVasPrimeDetails
                                            joodType="BASIC"
                                            vasLabel={vasPrice.vasType.label}
                                            oldVasPrice={vasPrice?.servicePrice
                                                ?.formattedValue}
                                            vasPrice={vasPrice
                                                ?.basicPrimeDiscountPrice
                                                ?.formattedValue}
                                        />
                                    {:else}
                                        <AtcJoodVasPrimeDetails
                                            joodType="BASIC"
                                            vasLabel={vasPrice.vasType.label}
                                            oldVasPrice={vasPrice?.servicePrice
                                                ?.formattedValue}
                                            vasPrice={vasPrice
                                                ?.basicPrimeDiscountPrice
                                                ?.formattedValue}
                                        />
                                    {/if}
                                {:else}
                                    {#if vasPrice?.vipPrimeDiscountPrice?.value > 0}
                                        {incrementServicePrimeCount()}
                                    {/if}
                                    <section>
                                        <div
                                            class="c_vas-details-list-year c-vas-safecaurd"
                                        >
                                            {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                                <span
                                                    class="c_product-details-title-reduction"
                                                >
                                                    {#if lang === "en"}
                                                        {vasPrice
                                                            .percentageDiscount
                                                            .value}%&nbsp;<span
                                                            >{getStr(
                                                                "product.pourcentageDiscount.off",
                                                            )}</span
                                                        >
                                                    {:else}
                                                        %{vasPrice
                                                            .percentageDiscount
                                                            .value}&nbsp;<span
                                                            >{getStr(
                                                                "product.pourcentageDiscount.off",
                                                            )}</span
                                                        >
                                                    {/if}
                                                </span>
                                            {/if}
                                            {vasPrice.vasType.label}
                                        </div>
                                        <div
                                            class="vas-name"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <span>{vasPrice.vasType.name}</span>
                                        </div>
                                    </section>
                                    {#if vasPrice.discountedPrice !== null}
                                        <div
                                            class="c_product-price-prices"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <div class="c_product-price-was-is">
                                                <span
                                                    class="c_product-price-previous"
                                                    >{vasPrice.servicePrice
                                                        .value}</span
                                                >
                                            </div>
                                            <div
                                                class="c_product-price-current"
                                            >
                                                <AtcFormatPrice
                                                    priceData={vasPrice.discountedPrice}
                                                    displayFreeForZero={true}
                                                    addCurrency={true}
                                                />
                                            </div>
                                        </div>
                                    {:else}
                                        <div
                                            class="c_vas-details-list-price"
                                            class:hide={vasPrice.noPlanOption}
                                        >
                                            <AtcFormatPrice
                                                priceData={vasPrice.servicePrice}
                                                displayFreeForZero={true}
                                                addCurrency={true}
                                            />
                                        </div>
                                    {/if}
                                {/if}
                            </div>
                        {:else}
                            <section>
                                <div
                                    class="c_vas-details-list-year c-vas-safecaurd"
                                >
                                    {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
                                        <span
                                            class="c_product-details-title-reduction"
                                        >
                                            {#if lang === "en"}
                                                {vasPrice.percentageDiscount
                                                    .value}%&nbsp;<span
                                                    >{getStr(
                                                        "product.pourcentageDiscount.off",
                                                    )}</span
                                                >
                                            {:else}
                                                %{vasPrice.percentageDiscount
                                                    .value}&nbsp;<span
                                                    >{getStr(
                                                        "product.pourcentageDiscount.off",
                                                    )}</span
                                                >
                                            {/if}
                                        </span>
                                    {/if}
                                    {vasPrice.vasType.label}
                                </div>
                                <div
                                    class="vas-name"
                                    class:hide={vasPrice.noPlanOption}
                                >
                                    <span>{vasPrice.vasType.name}</span>
                                </div>
                            </section>
                            {#if vasPrice.discountedPrice !== null}
                                <div
                                    class="c_product-price-prices"
                                    class:hide={vasPrice.noPlanOption}
                                >
                                    <div class="c_product-price-was-is">
                                        <span class="c_product-price-previous"
                                            >{vasPrice.servicePrice.value}
                                        </span>
                                    </div>
                                    <div class="c_product-price-current">
                                        <AtcFormatPrice
                                            priceData={vasPrice.discountedPrice}
                                            displayFreeForZero={true}
                                            addCurrency={true}
                                        />
                                    </div>
                                </div>
                            {:else}
                                <div
                                    class="c_vas-details-list-price"
                                    class:hide={vasPrice.noPlanOption}
                                >
                                    <AtcFormatPrice
                                        priceData={vasPrice.servicePrice}
                                        displayFreeForZero={true}
                                        addCurrency={true}
                                    />
                                </div>
                            {/if}
                        {/if}

                        {#each serviceProductCodes as code}
                            {@const freeGiftData =
                                vasPrice.serviceFreeGiftMap[code]}
                            {#if !!freeGiftData}
                                {@const sourceQty =
                                    freeGiftData.freeGiftQtuantities[
                                        "sourceQty"
                                    ]}
                                {@const targetQty =
                                    freeGiftData.freeGiftQtuantities[
                                        "targetQty"
                                    ]}
                                <div
                                    class="c_gift-items js-freegiftexist"
                                    class:js-freegiff-set-fixed={sourceQty ===
                                        targetQty}
                                    class:c_gift-items-fixed={sourceQty ===
                                        targetQty}
                                >
                                    <div class="c_gift-items-title">
                                        {#if sourceQty < targetQty}
                                            <span
                                                class="c_gift-items-title--color"
                                            >
                                                {getStr(
                                                    "product.promotion.freegifts.display.lower.txt",
                                                    [sourceQty],
                                                )}</span
                                            >
                                        {:else}
                                            <span
                                                class="c_gift-items-title--color"
                                            >
                                                {getStr(
                                                    "text.fixed.freegift.count.display.txt.vas",
                                                    [sourceQty],
                                                )}
                                            </span>&nbsp;{getStr(
                                                "product.promotion.freegifts.item.worth.txt",
                                            )}&nbsp;
                                            <span
                                                class="c_gift-items-title--color mobile-font"
                                                >{freeGiftData.freeGiftWorth}
                                                {currency}</span
                                            >
                                        {/if}
                                    </div>
                                </div>
                            {/if}
                        {/each}
                    </div>
                    <div class="hide">
                        <div class="c_vas-details-list-button">
                            <form
                                id="vasAddToCartForm{product.code}{vasPriceIndex}-{loopindex}"
                                action={getContextedUrl("/cart/vas/add")}
                                modelAttribute="vasAddtocartForm"
                                method="post"
                                class="c_form js-formValidation js-vas-addtocart-form"
                                data-ajax="true"
                                data-submit="vasaddtocart"
                            >
                                <div
                                    id="test_vasAddToCartButton_$"
                                    style="display:inline"
                                >
                                    {#if vasPrice.noPlanOption}
                                        <input
                                            type="hidden"
                                            name="vasProductCode"
                                            value={entry.noPlanProductCode}
                                        />
                                        <input
                                            type="hidden"
                                            name="noPlanOption"
                                            value={vasPrice.noPlanOption}
                                        />
                                    {:else}
                                        <input
                                            type="hidden"
                                            name="vasProductCode"
                                            value={vasPrice.productCodes
                                                ?.length > 0
                                                ? vasPrice.productCodes
                                                : entry.serviceProductCode}
                                        />
                                    {/if}
                                    <input
                                        type="hidden"
                                        name="vasType"
                                        value={vasPrice.vasType.code}
                                    />
                                    <input
                                        type="hidden"
                                        name="priceType"
                                        value={vasPrice.priceType}
                                    />
                                    <input
                                        type="hidden"
                                        name="priceTo"
                                        value={vasPrice.priceTo}
                                    />
                                    <input
                                        type="hidden"
                                        name="productCode"
                                        value={product.code}
                                    />
                                    <input
                                        type="hidden"
                                        name="additionalAwsProductCode"
                                        value={vasPrice.additionalAwsProductCode}
                                    />
                                    <input
                                        type="hidden"
                                        name="additionalAwsVasGroup"
                                        value={vasPrice.additionalAwsVasGroup}
                                    />
                                    <input
                                        type="hidden"
                                        name="combinedVas"
                                        value={entry.combinedVas}
                                    />
                                    <input
                                        type="hidden"
                                        name="parentEntryNumber"
                                        value={parentEntryNumber}
                                    />
                                    <input type="hidden" name="qty" value="1" />
                                    <input
                                        type="hidden"
                                        name="serviceGroupNumber"
                                        value=""
                                    />
                                    {#each serviceProductCodes as code}
                                        {@const freeGiftData =
                                            vasPrice.serviceFreeGiftMap[code]}
                                        {#if !!freeGiftData}
                                            {#each freeGiftData.freeGiftProducts as freeGift}
                                                <input
                                                    type="hidden"
                                                    name="freeGiftCodesMap[{code}]"
                                                    value={freeGift.code}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="freeGiftRuleIdMap[{code}]"
                                                    value={freeGiftData.freeGiftRuleId}
                                                />
                                            {/each}
                                        {/if}
                                    {/each}
                                    <input
                                        type="hidden"
                                        name="vasGroup"
                                        value={entry.vasGroup}
                                    />
                                    <input
                                        type="hidden"
                                        name="showApplyService"
                                        value={entry.showApplyService}
                                    />
                                    <input
                                        type="hidden"
                                        name="vasEntryShowService"
                                        value={entry.showApplyService}
                                    />
                                    <input
                                        type="hidden"
                                        name="entryNumber"
                                        value={typeof selectedEntrynumber ===
                                        "number"
                                            ? selectedEntrynumber
                                            : ""}
                                        id="vasEntryNumber"
                                        class="js-vasEntryNumber"
                                    />
                                    <button
                                        type="button"
                                        id="vas-button-{product.code}{vasPriceIndex}"
                                        aria-labelledby="vas-button-{product.code}{vasPriceIndex} vasAddToCartForm{product.code}{vasPriceIndex}"
                                        class="c_vas-details-list-button-vas"
                                        class:selected={vasPrice.defaultWarranty}
                                    >
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </button>
            {/each}
        </div>
    {/if}
    {#if primeEnabled && servicePrimeCount > 0}
        <div class="c_vas-details-jood-reveal-container">
            {#if membershipType !== "NOPRIME"}
                {#if membershipType === "BASICPRIME"}
                    <AtcJoodVasService
                        vasEntry={entry}
                        linkType="upgrade"
                        joodType="VIP"
                        isRevealState={true}
                        typeClass="jood-cart-guest"
                    />
                {/if}
            {:else}
                <AtcJoodVasService
                    vasEntry={entry}
                    linkType="subscribeNow"
                    joodType="VIP"
                    isRevealState={true}
                    typeClass="jood-cart-guest"
                />
            {/if}
        </div>
    {/if}
</div>
<div class="row-flex">
    <div class="col-flex-xs-6 col-flex-md-3"></div>
    <div class="col-flex-xs-12 col-flex-md-9 simple-flex">
        <div
            class="js-apply-service-option-{product.code}-extra-vas-{parentEntryNumber}"
        >
            <div class="row-flex c_apply-service-title">
                {product.attributeLabels.extraContentApplyService}
            </div>
            <div class="row-flex c_apply-service">
                <div
                    data-applyservice="false"
                    data-entrynumber={parentEntryNumber}
                    data-vasGroup={entry.vasGroup}
                    class="col-flex-xs-6 col-flex-sm-6 col-flex-md-6 c_apply-service-content js-apply-service-content selected"
                >
                    <span class="selected-icon"></span>
                    <img alt="ExtraSetup" src={extra_service_store_device} />
                    <div class="c_apply-service-info">
                        {product.attributeLabels.extraTextNotApplyService}
                    </div>
                </div>
                <div class="c_apply-service-or-divider">or</div>
                <div
                    data-applyservice="true"
                    data-entrynumber={parentEntryNumber}
                    data-vasGroup={entry.vasGroup}
                    class="col-flex-xs-6 col-flex-sm-6 col-flex-md-6 c_apply-service-content js-apply-service-content"
                >
                    <span class="selected-icon"></span>
                    <img alt="ExtraSetup" src={extra_service_unpack_device} />
                    <div class="c_apply-service-info">
                        {product.attributeLabels.extraTextApplyService}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div
    class="row-flex c_no-service-div"
    class:hide={noPlanVasOptionSelected != true}
>
    <div class="col-flex-xs-12 col-flex-md-12 c_no-service-text">
        <span class="orange-text">{getStr("extra.pdp.service.areyousure")}</span
        >
        <span>{getStr("extra.pdp.service.yourdevice")}</span>
    </div>
</div>
