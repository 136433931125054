import { get } from "svelte/store";
import { getAppConfig } from "../../../common/config/app-config";
import {
  APP_CONSTANTS,
  getBaseWebServiceUrl,
} from "../../../common/config/app-constants";
import {
  httpGetJSON,
  httpPostJSON,
  httpPostRaw,
} from "../../../common/services/ajax-services";

import { rrGetPDPPagePlacements } from '../../../common/services/rich-relevance';
import { algoliaClient, algoliaIndexConfig } from "../../../common/store/algolia-config-store";
import { convertJsonObjectToFormData, getLangSpecificFieldname, getStr, parseCashback, parsePriceString } from "../../../common/util";
import { addToCartContent, currentLocation, currentProductImage, giftCardAmount, giftcardemail, giftcardmsg, hightlightDetailsClickOnSlideIndex, nearestPickupStore, openAddToCartDrawer, openSnackbar, pdpServicesData, productDetails, productImages, qacAtcPopupFlag, qacProductCode, rsaBrand, rsaPlateNo, rsaYear, selectedGifts, showHighlightDetailsPopup, showPdpMoreInfoModal, showPdpTileInfo } from "../store/pdp_store";
const {
  lang = "en",
  countryCode,
  encodedContextPath,
  CSRFToken,
  jessionId,
  userSelectedCity,
  userDefaultCityCode,
  userSelectedCityCode,
  currencyIso,
  primeEnabled,
  primeLevel,
  restockable,
  svelteATCEnabled,
  pageId,
  pageType
} = getAppConfig();
const { tasheelInfo } = window["ACC"].config.algoliaConfig;

let BASE_WEB_SERVICE_URL = getBaseWebServiceUrl();
let isOutOfStock;
let userCityCode = !userSelectedCityCode
  ? userDefaultCityCode
  : userSelectedCityCode;

// Get the algolia query id from URL
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop as string),
});

let algoliaQueryIdValue = params['algoliaQueryId'] || '';

export function processRRHits(rr) {
  const processed = Object.assign({}, rr);
  processed.hits = processed.hits.map((hit) =>
    Object.assign({}, hit, {
      name: hit[getLangSpecificFieldname("name", lang)],
      price: {
        formattedValue: parsePriceString(hit.wasPrice, countryCode),
        value: hit.wasPrice,
      },
      aftreCashBackPrice: hit.hasCashBack
        ? {
          formattedValue: parsePriceString(
            hit.price -
            +parseCashback(hit.cashback, countryCode),
            countryCode
          ),
        }
        : {
          formattedValue: parsePriceString(
            hit.price,
            countryCode
          ),
        },
      cashBackPrice: parseCashback(hit.cashback, countryCode),
      productStatus:
        hit[getLangSpecificFieldname("productStatus", lang)],
      productStatusHtml:
        hit[getLangSpecificFieldname("productStatusHtml", lang)],
      freeGiftQtuantities: hit.freegiftworth,
      percentageDiscount: {
        formattedValue: hit.priceValueDiscountPercentage.toString(),
        value: hit.priceValueDiscountPercentage,
      },
      discountPrice: {
        value: hit.priceValueDiscount,
        formattedValue: parsePriceString(
          hit.priceValueDiscount,
          countryCode
        ),
      },
      url: hit[getLangSpecificFieldname("url", lang)],
      code: hit.productCode,
    })
  );

  return processed;
}

export function getAlgoliaDataForPlacements(placements) {
  let { productIndexKey } = get(algoliaIndexConfig);
  const queries = [];
  placements.forEach((data) => {
    let obj = {
      indexName: productIndexKey,
      query: "*",
      params: {
        filters: `price>0`,
        facetFilters: [data.facetFilter]
      },
    };
    queries.push(obj);
  });

  return get(algoliaClient).multipleQueries(queries).then((data) => {
    data.results = data.results.map((item, index) => {
      item.strategyMessage = placements[index].message;
      item.key = placements[index].key;
      item.hits = item.hits.map((i, _index) =>
        Object.assign({}, i, {
          rrClickTrackingURL:
            placements[index].items[_index].clickTrackingURL,
        })
      );
      return item;
    });
    return data.results;
  });
}

export async function processRRPlacements(placements) {
  if (placements && placements.length > 0) {
    placements = placements.map(data => {
      let facetFilter = [];
      data.items.forEach(recommendedData => facetFilter.push(`productCode:${recommendedData.id}`));
      data.facetFilter = facetFilter;
      data.key = data.placement_name.split('.')[1]
      return data;
    });

    return placements;
  }
  return [];
}

export async function pdpRichRelevance() {
  return rrGetPDPPagePlacements()
    .then(placements => processRRPlacements(placements))
    .then(placements => getAlgoliaDataForPlacements(placements));
}

export async function getTasheelCardDetails() {
  // const url = `${APP_CONSTANTS.APP_BASE_URL}/${getBaseWebServiceUrl()}/${APP_CONSTANTS.TasheelCardResp}`;
  // const response = await httpGetJSON(url, {} , false);
  // console.log(response , "TasheelCardResp");
}

export async function getProductImages(sku) {
  const url = `${APP_CONSTANTS.AMPLIENCE_IMAGE_BASE_URL_S}${sku}_800.json`;
  const params = {
    deep: true,
  };
  const response = await httpGetJSON(url, params, {}, false);
  if (response.name && response.items.length) {
    productImages.set(response.items);
    const nVideos = response.items.filter(x => x.type === 'video').length;
    const nImages = response.items.length - nVideos;
    const hasVideo = nVideos > 0;
    setPDPViewedTotalImages(nImages, hasVideo);
    currentProductImage.set(response.items[0].src);
    return true;
  } else {
    setPDPViewedTotalImages(0, false);
    throw new Error(`ERROR fetching banner data`);
  }
}

export async function getSiblingProdMain(sku) {
  const url = `${window['ACC'].config.encodedContextPath}/p/${sku}${APP_CONSTANTS.GETPRODUCT}`;

  const response = await httpGetJSON(url, {}, false);
  var tempArr = window['ACC'].config.productDetails;
  window['ACC'].config.productDetails = addOrReplace(tempArr, response);;
  productDetails.set(addOrReplace(tempArr, response));
}

export function setPDPViewedTotalImages(number_of_product_images: number, product_video_flag: boolean) {
  // setPDPViewedData({ number_of_product_images, product_video_flag });
}
export function setPDPViewedRatingData(rating: number, no_of_reviews: number) {
  setPDPViewedData({ no_of_reviews });
}
export function setPDPVariantData(data) {
  var _variant = [];
  if (data) {
    data.map((val) => {
      val.variantValues.map((v1) => {
        _variant.push({ variant_name: val.name, value: v1.name })
      })
    })
  }
  setPDPViewedData({ variant: _variant });
}
export function setPDPViewedData(eventObjPartial) {
  const mpPageLoad = window['MIXPANEL'].config.mixPanelPageLoad;
  if (mpPageLoad) {
    mpPageLoad.eventObj = Object.assign({}, mpPageLoad.eventObj, eventObjPartial);
    if ('requireMoreData' in mpPageLoad) {
      mpPageLoad['requireMoreData'] -= 1;
    }
  }
  emitPDPViewedEventIfDataAvailable();
}
export function emitPDPViewedEventIfDataAvailable() {
  const mpPageLoad = window['MIXPANEL'].config.mixPanelPageLoad;
  if (mpPageLoad && ('requireMoreData' in mpPageLoad) && mpPageLoad['requireMoreData'] === 0) {
    window['ACC'].mixpanel.track(mpPageLoad.eventName, mpPageLoad.eventObj);
  }
}
function addOrReplace(arr, newArr) {
  for (const main of Object.keys(arr)) {
    for (const subset of Object.keys(newArr)) {
      if (main === subset) {
        arr[main] = newArr[subset]
      }
    }
  }
  return arr;
}

export function getStyle(data, target) {
  if (target === 'title') {
    if (data?.includes('#')) {
      return `color:${data};`
    } else {
      return `color: #FFFFFF;`
    }
  }

  if (target === 'description') {
    if (data?.includes('#')) {
      return `color:${data};`
    } else {
      return `color: #FFFFFF;`
    }
  }
}

export async function notifyAlert({ email, mobileNo, alertType, productCode, CSRFToken, city }) {
  const body = `email=${email}&mobile=${mobileNo}&alertType=${alertType}&productCode=${productCode}&city=${city}&CSRFToken=${CSRFToken}`;
  const url = `${encodedContextPath}/${APP_CONSTANTS.NOTIFY_ALERT}`;

  return httpPostJSON(url, body, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });
}

export function showTileInfo(index) {
  hightlightDetailsClickOnSlideIndex.set(index);
  showPdpTileInfo.set(true);
  showHighlightDetailsPopup.set(true);
  if (typeof window['ACC']?.extragtm?.click?.pdpKeyHighlightClick === 'function') {
    window['ACC'].extragtm.click.pdpKeyHighlightClick();
  }
}

export async function getNearestLocation(data) {
  const url = `${getBaseWebServiceUrl()}/${APP_CONSTANTS.NEAREST_STORE}`;
  const { lang, jessionId } = getAppConfig();
  const params = {
    fields: "FULL",
    lang,
    deviceId: jessionId,
    productCode: data.productCode,
    latitude: data.latitude,
    longitude: data.longitude
  };
  const response = await httpGetJSON(url, params, false);
  if (response && !response?.errors?.length && response.displayName !== null) {
    nearestPickupStore.set(response)
  } else if (response && response.errors.length && (get(productDetails) as any)?.cfsPosPickUpInStoreData?.alternativePos?.displayName !== null) {
    nearestPickupStore.set((get(productDetails) as any)?.cfsPosPickUpInStoreData?.alternativePos);
  } else {
    nearestPickupStore.set(null);
  }
}

export async function error(err) {
  currentLocation.set({});
  const pd = get(productDetails);
  if (pd?.cfsPosPickUpInStoreData?.alternativePos?.displayName?.length > 0) {
    nearestPickupStore.set(pd.cfsPosPickUpInStoreData?.alternativePos);
  } else {
    nearestPickupStore.set(null);
  }
}

export async function success(pos) {
  currentLocation.set(pos.coords);
  if ((get(currentLocation) as any).latitude !== null && (get(currentLocation) as any).longitude !== null) {
    let body = {
      productCode: (get(productDetails) as any)?.code || (get(qacProductCode)),
      latitude: (get(currentLocation) as any)?.latitude,
      longitude: (get(currentLocation) as any)?.longitude
    }
    getNearestLocation(body)
  } else {
    if ((get(productDetails) as any).cfsPosPickUpInStoreData?.alternativePos?.displayName !== null) {
      nearestPickupStore.set((get(productDetails) as any).cfsPosPickUpInStoreData?.alternativePos?.displayName);
    } else {
      nearestPickupStore.set(null);
    }
  }
}


export async function postAddToCartData(body) {
  window['ACC'].loader.showLoader();
  const atcUrl = svelteATCEnabled ? APP_CONSTANTS.ADDTOCART_JSON : APP_CONSTANTS.ADDTOCART_HTML;
  const url = `${encodedContextPath}/${atcUrl}`;

  return httpPostJSON(url, body, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });
}


export async function getTasheelDetails() {
  const url = `${encodedContextPath}/${APP_CONSTANTS.GET_TASHEEL_DETAILS}`;
  return httpGetJSON(url, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });

}


export async function postAddBundleToCartData(body) {
  window['ACC'].loader.showLoader();
  const atcBundleUrl = svelteATCEnabled ? APP_CONSTANTS.ADDBUNDLETOCART_JSON : APP_CONSTANTS.ADDBUNDLETOCART_HTML;
  const url = `${encodedContextPath}/${atcBundleUrl}`;

  return httpPostJSON(url, body, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });
}

export const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};


export function handleCartResponse(response) {
  const isATCSuccessful = (svelteATCEnabled && response?.productAdded) || (!svelteATCEnabled && !!response?.addToCartLayer && !response?.errorMessage);
  if (isATCSuccessful) {
    showPdpMoreInfoModal.set(false);
    const atcData = svelteATCEnabled ? response : response.addToCartLayer;
    addToCartContent.set(atcData);
    openAddToCartDrawer.set(true);
    if (pageId === "homepage" || pageId === "productList" || pageId === "search" || pageType === "category" || pageType === "BRAND") {
      qacAtcPopupFlag.set(true);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }      /* Below one is to intialize RR for addToCart popup*/
    window['ACC'].rrvariables.addToCartItemId = (get(productDetails) as any)?.code;
    window['ACC'].richrelevance?.onLoad('ADDTOCART');
    window['ACC'].extragtm.var.cartTotal = response?.cartData?.total ? response?.cartData?.total : response?.cartTotal;
    if (typeof window['ACC']?.minicart?.updateMiniCartDisplay === 'function') {
      window['ACC'].minicart.updateMiniCartDisplay();
    }
    // addToCartV2

    const gtmData = response?.entry?.product || response?.productDataForGtm;
    window['ACC'].extragtm.click.addToCartV2([gtmData]);

    setTimeout(function () {
      const atcLayerDOM = document.getElementById('addToCartLayer').querySelector("#amp-page-slot7");
      let amplianceData = JSON.parse(atcLayerDOM?.getElementsByClassName('js-amp-config')[0]?.getAttribute('data-ampliance-option') || null);

      if (amplianceData) {
        window.AmpCa.utils = new window.AmpCa.Utils();
        window.AmpCa.utils.getHtmlServiceData({
          auth: {
            baseUrl: amplianceData.baseUrl,
            id: amplianceData.id,
            store: amplianceData.store,
            locale: amplianceData.locale,
            templateName: amplianceData.templateName
          },
          callback: function (data) {
            document.getElementsByClassName("js-addtocart-ampliance-banner")[0].innerHTML = data;
            setupAddToCartTasheelBannerObserver(amplianceData);
          }
        });
      }
    }, 0);


    // Remove selected class from LMD services when add to cart popup is rendered
    const addToCartLmdServiceElements = document.querySelectorAll('.js-apply-lmd-service');

    if (addToCartLmdServiceElements?.length > 0) {
      // Iterate over LMD service elements and remove selected class
      addToCartLmdServiceElements.forEach(element => element.classList.remove('selected'));
    }

  } else if (response.errorMessage || response.errorMsg) {
    if (response.errorMessage) openSnackBar(strip(response.errorMessage));
    else openSnackBar(getStr(response.errorMsg));
    openSnackbar.update(x => x = false);
  } else {
    openSnackBar(strip(error));
    openSnackbar.update(x => x = false);
    window['ACC'].loader.hideLoader();
  }
  if ((get(selectedGifts) as any).length) {
    selectedGifts.set([]);
  }
  window['ACC'].loader.hideLoader();
}

function setupAddToCartTasheelBannerObserver({ id }) {
  const io = new IntersectionObserver((entries) => {
    entries.forEach(e => {
      if (e.isIntersecting) {
        window['ACC'].extragtm.trackBannerView(id, 'Add to cart tasheel banner');
        window['ACC'].mixpanel.trackBannerView(id, 'Add to cart tasheel banner');
        io.unobserve(e.target);
      }
    });
  });

  setTimeout(() => {
    io.observe(document.querySelector('.js-addtocart-ampliance-banner'));
  }, 500);
}

export function giftCartPayload(detail) {
  const { giftSelected, giftGroupIndex } = detail;
  const vasSelections: any[] = get(pdpServicesData);
  console.log(detail);

  let dataObj = {
    sourceProductCode: (get(productDetails) as any).code,
    bundleProductCodes: (get(productDetails) as any).code,
    CSRFToken: getAppConfig().CSRFToken,
    hasFreeGift: (get(productDetails) as any).hasFreeGift ? true : false,
    algoliaQueryId: algoliaQueryIdValue,
    ampProdImgSec: ['PDP-gift', 'PDP-popup-cartList'],
    pageId: getAppConfig().pageId,
    selectableGift: (get(productDetails) as any)?.freeGiftDataList[giftGroupIndex].freeGiftQtuantities.sourceQty < (get(productDetails) as any)?.freeGiftDataList[giftGroupIndex].freeGiftQtuantities.targetQty ? true : false,

    // vasSelections
    ...(vasSelections?.length > 0 && { vasSelections })
  }
  if (giftSelected[0] === 'discount') {
    dataObj['acPriceDiscountSelected'] = true;
  } else {
    dataObj['fgProductCodes'] = giftSelected;
    dataObj['acPriceDiscountSelected'] = false;
    dataObj['freeGiftRuleId'] = (get(productDetails) as any)?.freeGiftDataList[giftGroupIndex]?.freeGiftRuleId
  }

  const requestobjString = convertJsonObjectToFormData(dataObj);
  return requestobjString;
}

export function createBundlePayload(index) {
  let objectString = '';
  let dataObj = {
    bundleRuleId: (get(productDetails) as any)?.specialOfferDataList[index]?.bundleRuleId,
    sourceProductCode: (get(productDetails) as any)?.code,
    initialPrice: (get(productDetails) as any)?.specialOfferDataList[index]?.initialBundlePrice,
    targetBundlePrice: (get(productDetails) as any)?.specialOfferDataList[index]?.targetBundlePrice,
    CSRFToken: getAppConfig().CSRFToken,
    bundleProductCodes: [],
    algoliaQueryId: algoliaQueryIdValue,
  }

  if ((get(productDetails) as any)?.specialOfferDataList[index]?.specialOfferProducts.length > 0) {
    (get(productDetails) as any)?.specialOfferDataList[index].specialOfferProducts.forEach(element => {
      dataObj.bundleProductCodes.push(element.code);
    });
  }

  for (let key in dataObj) {
    objectString += `${key}=${dataObj[key]}&`;
  }
  objectString += `CSRFToken=${getAppConfig().CSRFToken}`;
  return objectString;
}

export function isValidRsaForm(errMsgAr) {
  let rsaPlateNoValidation = /^([\u0660-\u0669a-zA-Z-\d]{1,15})$/;
  if (get(rsaBrand) && get(rsaYear) && get(rsaPlateNo) && (rsaPlateNoValidation.test(get(rsaPlateNo)) === true && (get(rsaPlateNo) && get(rsaPlateNo).length <= 15))) {
    return "true";
  } else {
    if (get(rsaBrand) == '') {
      openSnackBar(errMsgAr[0]);
      openSnackbar.update(x => x = false);
      return "false";
    }
    if (get(rsaYear) == '') {
      openSnackBar(errMsgAr[1]);
      openSnackbar.update(x => x = false);
      return "false";
    }
    if (get(rsaPlateNo) == '') {
      openSnackBar(errMsgAr[2]);
      openSnackbar.update(x => x = false);
      return "false";
    }
    if (rsaPlateNoValidation.test(get(rsaPlateNo)) === false) {
      openSnackBar(errMsgAr[3]);
      openSnackbar.update(x => x = false);
      return "false";
    }
  }
}
export function createRequestPayload() {
  const pd: any = get(productDetails);
  const vasSelections: any[] = get(pdpServicesData);

  const dataObject = {
    productCodePost: pd?.code,
    freeGiftNotAvailable: pd?.freeGiftNotAvailable,
    hasFreeGift: !!pd?.hasFreeGift,
    algoliaQueryId: algoliaQueryIdValue,
    CSRFToken,

    // vasSelections
    ...(vasSelections?.length > 0 && { vasSelections }),

    // purchasable dependent
    ...(pd?.purchasable && {
      qty: 1
    }),

    // isRSAItem
    ...(pd?.isRSAItem && {
      carBrand: get(rsaBrand),
      carYear: get(rsaYear),
      plateNumber: get(rsaPlateNo)
    }),

    // isDigitalGiftCard
    ...(pd?.isDigitalGiftCard && {
      giftCardAmount: get(giftCardAmount),
      digitalGiftCard: true,
      giftCardReceiverEmailId: get(giftcardemail),
      giftCardMessage: get(giftcardmsg)
    })
  };

  const objectString = convertJsonObjectToFormData(dataObject);

  return objectString;
}

export function closeGiftPopupAfterGiftSelection(data) {
  selectedGifts.set(data);
  document.querySelector('#giftModal').classList.remove('show');
  document.querySelector('body').classList.remove('modal-open');

  const mdbackdrop = document.querySelectorAll('.modal-backdrop');
  mdbackdrop.forEach((element) => {
    element.classList.remove('modal-backdrop', 'show');
  });
  document.getElementById("giftModal").style.display = "none";
}


export async function openSnackBar(msg) {
  // snackbarLabel.set(msg);
  // openSnackbar.update(x => x = true);
  window.ACC.main.showCouponCodeSnackbar('', false, msg);
}

export function strip(html) {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent.trim() || "";
}

export function submitReviewToServer(review) {
  // test-freak test API
  const testFreaksHeaders = {
    'Content-Type': 'application/json'
  };
  const { CSRFToken } = getAppConfig();
  const url = `${encodedContextPath}/${APP_CONSTANTS.POST_TEST_FREAKS_REVIEW}?CSRFToken=${CSRFToken}`;
  // url.searchParams.append('client_id', APP_CONSTANTS.TEST_FREAKS_CLIENT_ID);
  const body = JSON.stringify({ review });

  return httpPostRaw(url, body, testFreaksHeaders, false);
}

export function manualBundleCartPayload(data) {
  const vasSelections: any[] = get(pdpServicesData);
  let dataObj = {
    sourceProductCode: (get(productDetails) as any).code,
    CSRFToken: getAppConfig().CSRFToken,
    bundleProductCodes: data,
    algoliaQueryId: algoliaQueryIdValue,
    addManualBundleFreeGift: true,

    // vasSelections
    ...(vasSelections?.length > 0 && { vasSelections })
  }
  const requestobjString = convertJsonObjectToFormData(dataObj);
  return requestobjString;
}

export function getTestFreakDataForProductId(productId) {
  const url = APP_CONSTANTS.GET_TEST_FREAK_BASE
    .replace('${clientId}', APP_CONSTANTS.TEST_FREAKS_CLIENT_ID);

  const testFreaksHeaders = {
    'Content-Type': 'application/json'
  };

  return httpGetJSON(url, { key: productId, '-*': true }, testFreaksHeaders, false);
}

export function gtmForPdpService(event, category, family, productType, productName, brandName, sku, serviceName) {
  if (typeof window['ACC']?.extragtm?.click?.pdpServiceAction === 'function') {
    window['ACC'].extragtm.click.pdpServiceAction(event, category, family, productType, productName, brandName, sku, serviceName);
  }
}

export function getCategoryDetailsForPdp() {
  const categoryDetail = (get(productDetails) as any).categoryIdName;
  const [categoryId, categoryName] = categoryDetail.split('_');
  return { categoryId, categoryName }
}

export function gtmForGeekOutHeader(headerName, headerRank, highlightName) {
  if (typeof window['ACC']?.extragtm?.click?.geekOutHeaderClick === 'function') {
    window['ACC'].extragtm.click.geekOutHeaderClick(headerName, headerRank, highlightName);
  }
}

export function gtmForPdpSmallBox(name) {
  if (typeof window['ACC']?.extragtm?.click?.pdpSmallBoxClick === 'function') {
    window['ACC'].extragtm.click.pdpSmallBoxClick(name);
  }
}

export function generateArrayOfYears(count) {
  let year = new Date().getFullYear()
  let min = year - count
  let years = []

  for (var i = year; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function redirectToPdp(url: string) {
  if (url) {
    window.location.href = url;
  }
}

export function getSwogoBundleProducts(sku) {
  const url = `${APP_CONSTANTS.GET_SWOGO_BUNDLE}?skus=${sku}&longStyle=true`;

  return httpGetJSON(decodeURI(url), { '-*': true }, {}, false);
}

export async function getTamaraPriceForBundle(payload) {
  const url = `${getAppConfig().encodedContextPath}/${APP_CONSTANTS.GET_BUNDLE_TAMARA_PRICE}`;
  let body = {
    codes: payload
  }
  const response = await httpGetJSON(url, body, {}, true);
  return response;
}

export async function getCartId() {
  const url = `${BASE_WEB_SERVICE_URL}/users/carts/`;

  const response = await httpGetJSON(url, {}, {}, true);
  return response;
}

export async function getAddToCartJson(cartId) {
  const url = `${BASE_WEB_SERVICE_URL}/users/carts/${cartId}/entries/`;
  // window['ACC'].loader.showLoader();
  let payload = `appId=${APP_CONSTANTS.APP_ID}&quantity=1&code=${(get(productDetails) as any).code}&freeGiftNotAvailable=true&fields=FULL&lang=en&city=${userSelectedCity || userDefaultCityCode}&AppSessionToken=${jessionId}`;
  return httpPostJSON(url, payload, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });
}

export function convertProductDataToUnbxdProductData(productData) {
  let isComingSoon = productData?.comingSoon || false;
  let isRestockable = restockable && productData?.restockableCities?.includes(userCityCode) || false;
  let isInStock =
    !isComingSoon &&
    ((productData?.inStockCities?.length &&
      productData.inStockCities.indexOf(userCityCode) != -1) ||
      productData.stock?.stockLevelStatus === "inStock");
  let isSellingOutFast =
    !isInStock &&
    ((productData?.sellingOutFastCities?.length &&
      productData.sellingOutFastCities.indexOf(userCityCode) != -1) ||
      productData.stock?.stockLevelStatus === "lowStock");
  isOutOfStock = !(isComingSoon || isInStock || isSellingOutFast || isRestockable);
  const convertedProductData = {
    ...productData,
    brandName: productData?.brandName
      ? productData.brandName
      : lang === "en"
        ? productData.brandEn
        : productData.brandAr,
    name: productData?.name
      ? productData?.name
      : lang === "en"
        ? productData?.nameEn
        : productData?.nameAr,
    price: {
      formattedValue: parseFloat(productData?.price?.formattedValue),
      currencyIso: currencyIso,
    },
    discountPrice: {
      formattedValue: parseFloat(productData?.discountPrice?.formattedValue),
    },
    percentageDiscount: {
      formattedValue: productData.percentageDiscount?.value,
    },
    tasheelPriceData: {
      priceValueTasheel: {
        formattedValue:
          (productData &&
            productData.priceValueTasheel &&
            productData.priceValueTasheel) ||
          productData?.tasheelPriceData?.priceValueTasheel?.formattedValue,
        currencyIso: currencyIso,
      },
      tasheelInstallmentMonths:
        tasheelInfo && tasheelInfo?.tasheelInstallmentMonths,
      tasheelPercentage: tasheelInfo?.tasheelPercentage,
    },
    rating: productData.rating ?? 0,
    productTags: productData?.productTags?.length
      ? productData.productTags
      : productData?.productStatus
        ? [
          {
            label: productData?.productStatus,
            backgroundColorCode: "#FFECA1",
            textColorCode: "#051F2F",
          },
        ]
        : [],
    showAllFields: true,
    showBottomTags: true,
    showActionBtn: false,
    afterCashBackPrice: productData?.aftreCashBackPrice?.formattedValue
      ? productData?.aftreCashBackPrice?.formattedValue
      : null,
    cashBackPrice: productData?.cashBackPrice
      ? productData.cashBackPrice
      : null,
    basicPrimePrice: productData?.basicPrimePrice ? productData.basicPrimePrice : productData.productBasicPrimeData ? productData.productBasicPrimeData.primePrice.value : null,
    vipPrimePrice: productData?.vipPrimePrice ? productData.vipPrimePrice : productData.productVipPrimeData ? productData.productVipPrimeData.primePrice.value : null,
    primeEnabled: productData?.primeEnabled ? productData.primeEnabled : (primeEnabled || false),
    primeLevel: productData?.primeLevel ? productData.primeLevel : primeLevel ? primeLevel : null,
    tacticalVipPromo: productData?.tacticalVipPromo ? productData.tacticalVipPromo : productData?.productVipPrimeData?.tacticalPromo ? productData?.productVipPrimeData?.tacticalPromo : false,
    tacticalBasicPromo: productData?.tacticalBasicPromo ? productData.tacticalBasicPromo : productData?.productBasicPrimeData?.tacticalPromo ? productData?.productBasicPrimeData?.tacticalPromo : false,
    tacticalBasicPrimePrice: productData?.tacticalBasicPrimePrice ? productData?.tacticalBasicPrimePrice : productData?.productBasicPrimeData?.tacticalPrice?.formattedValue,
    tacticalVipPrimePrice: productData?.tacticalVipPrimePrice ? productData?.tacticalVipPrimePrice : productData?.productVipPrimeData?.tacticalPrice?.formattedValue,
    isProductInStock: ('stock' in productData && productData.stock.stockLevelStatus !== 'outOfStock'),
    cashback: productData?.cashBackPrice,
  };
  return convertedProductData;
}