<script lang="ts">
    import { getStr } from "../../../common/util";
    import Icon from "../../common/components/Icon.svelte";

    export let titleKey;
    export let icon;
    export let appleCareTitleDetails;
    const {
        imageUrl,
        label,
        code = "pdp-services-container",
    } = appleCareTitleDetails || {};
</script>

<section class="pdp-section-card {code}">
    <section class="card-title">
        {#if appleCareTitleDetails}
            <img src={imageUrl} alt={label} title={label} />
            <h2>{label}</h2>
        {:else}
            <Icon
                type="primary"
                desktopSize={40}
                mobileSize={30}
                glyph={icon}
            />
            <h2>{getStr(titleKey)}</h2>
        {/if}
    </section>
    <section class="card-content">
        <slot></slot>
    </section>
</section>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .pdp-section-card {
        margin-top: 8px;
        padding: 10px;
        background-color: $neutral-light-04;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        @include desktop-min-width {
            padding: 16px;
        }

        .card-title {
            display: flex;
            gap: 8px;
            align-items: center;

            h2 {
                padding: 0;
                flex-grow: 1;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                color: $neutral-dark-01;
                @include desktop-min-width {
                    font-size: 18px;
                }
            }
        }
    }
    :global {
        .pdp-section-card.servicesAppleCarePDPContent {
            .card-title {
                h2 {
                    margin: 0;
                }
                img {
                    border-radius: 6px;
                    width: 32px;
                    @include desktop-min-width {
                        width: 43px;
                    }
                }
            }
            .card-content {
                margin-top: 16px;
                
                @include responsive {
                    margin-top: 8px;
                }
                .option-code {
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 2px;
                    line-height: 16px;
                }
                .pdp-service-mini-card {
                    @include responsive {
                        padding: 10px 10px 17px 10px;
                    }
                    .subtitle,
                    p {
                        line-height: 16px;
                        margin: 0;
                    }
                    .content {
                        margin-top: 8px;
                        @include desktop-min-width {
                            margin-top: 20px;
                        }
                    }
                    .apple-service-container {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .extra-service-option-section {
                            flex-grow: 1;
                            .mdc-layout-grid__inner {
                                flex-flow: column;
                                width: 100%;
                            }
                        }
                    }
                    .title {
                        display: none;
                    }
                }
            }
        }
    }
</style>
