export const environment = {
  IS_PRODUCTION: false,
  IS_DEMO_MODE: false,
  API_BASE_URL: 'https://stageapi.extra.com',
  API_CLIENT_ID: 'web',
  API_CLIENT_SECRET: 'c6HyoQeV57n7ZHf0GyqSvn78rguCbzspesj8gI94',
  TTL_24_DOMAIN: 'extra.com',
  BACKUP_TOKEN: 'h-Qjsw1Anzqu65lAwmgBsQkamqk',
  AMPLIENCE_API_BASE: 'https://bdkbpklt25el1gonxadugljq5.staging.bigcontent.io/content/',
};
