<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { fade } from 'svelte/transition';

  import { getAppConfig } from '../../../../common/config/app-config';
  import { checkForMegaSaleProduct, getStr } from '../../../../common/util';
  import { cartLoginDetails } from '../../../header/store/header_store';
  import { redirectToPdp } from '../../../product-details/services/pdp-data-services';
  import ProductBottomTags from './components/ProductBottomTags.svelte';
  import ProductTileActionBtn from './components/ProductTileActionBtn.svelte';
  import ProductTileHead from './components/ProductTileHeader.svelte';
  import ProductTileImage from './components/ProductTileImage.svelte';
  import ProductTileName from './components/ProductTileName.svelte';
  import ProductTileOffer from './components/ProductTileOffer.svelte';
  import ProductTilePriceNewGroup from './components/ProductTilePriceNewGroup.svelte';
  import ProductTileRatingReviews from './components/ProductTileRatingReviews.svelte';

  /*
        Schema of input fiels::
            brandName:: String
            name: string
            price: {
                formattedValue: string,
                currencyIso: string
            }
            discountPrice: {
                formattedValue:
            },
            percentageDiscount: {
                formattedValue,
            },
            tasheelPriceData: {
                priceValueTasheel: {
                    formattedValue,
                    currencyIso
                },
                tasheelInstallmentMonths,
                tasheelPercentage
            },
            rating: string,
            numberOfReviews: string|number
            imageUrl: string,
            code: string,
            productTags: Arraye[object],object: type of Tag component input(properties)
            freeGiftCount:number,
            offersLabel: string (like: n Offers),
            productBottomTags: [object], object: type of featureTag component input(properties)
                [{content: string}]


            mobileTileWidth: number (main tile width for mobile resolution);
            desktopTileWidth: number (main tile width for desktop resolution);

            mobileImageWidth: number (product image<inside tile> width for mobile resolution);
            desktopImageWidth:number (product image<inside tile> width for desktop resolution);
            mobileImageHeight: number
            desktopImageHeight: number


            ::For configurable view, based on need ::
              showAllFields,
              showProductTags,
              showFavIcon,
              showPrice,
              showRatingReview,
              showOfferAndGift,
              showBottomTags,
              showActionBtn
    */

  export let {
    brandName,
    name,
    price,
    discountPrice,
    percentageDiscount,
    rating,
    tasheelPriceData,
    code,
    productTags,
    amplienceProductBaseUrl,
    freeGiftCount,
    offersLabel,
    numberOfReviews,
    productBottomTags,
    afterCashBackPrice,
    cashBackPrice,
    hasCashBack,
    pdpUrl,
    basicPrimePrice,
    vipPrimePrice,
    primeEnabled,
    primeLevel,
    tacticalVipPromo,
    tacticalBasicPromo,
    isProductInStock,
    tacticalVipPrimePrice,
    tacticalBasicPrimePrice,
    aftreCashBackPrice,
    wasPrice,
    priceValueTasheel,
    cashback,
    priceValueDiscountPercentageFlag,
    plpEligibleForTamara,
    plpEligibleForTamaraBlue,
    plpEligibleForTamaraGold,
    plpEligibleForTabby,
    productDataForGTM,
    restockableCities,
    restockableTagEn,
    restockableTagAr,
    isAddToCartEnabled,
    urlEn,
  } = {};
  export let favIconMobileWidth = 24;
  export let favIconDesktopWidth = 32;

  export let mobileTileWidth = 170;
  export let desktopTileWidth = 318;

  export let mobileImageWidth = 146;
  export let desktopImageWidth = 268;
  export let mobileImageHeight = 130;
  export let desktopImageHeight = 268;

  export let showAllFields = true;
  export let showProductTags = showAllFields;
  export let showFavIcon = showAllFields;
  export let showPrice = showAllFields;

  export let showRatingReview = showAllFields;
  export let showOfferAndGift = showAllFields;
  export let showBottomTags = showAllFields;
  export let showActionBtn = showAllFields;
  export let isBundleProduct = false;
  export let isRRComponent = false;

  const { outOfStock, categoryCode, categoryName, encodedContextPath, userSelectedCityCode, userDefaultCityCode } =
    getAppConfig();

  let wishlistedProductCodes = [];

  const dispatch = createEventDispatcher();
  const primeCardClass = primeEnabled && (basicPrimePrice > 0 || vipPrimePrice > 0) ? 'product-card-has-prime' : '';

  const toggleWishlist = data => {
    // data.detail.event.stopPropagation();
    dispatch('toggleFav', data.detail);
  };

  $: wishlisted = wishlistedProductCodes.indexOf(code) > -1;
  $: isRestockable = restockableCities?.includes(userSelectedCityCode || userDefaultCityCode);
  $: if (isProductInStock === false && !isRestockable) {
    productTags = [
      {
        label: outOfStock,
        theme: 'gray',
        borderRadLBot: 12,
        borderRadRBot: 12,
        theme: 'gray',
      },
    ];
  }
  $: sellingPrice =
    discountPrice?.formattedValue && discountPrice.formattedValue > 0
      ? discountPrice.formattedValue
      : price?.formattedValue;
  cartLoginDetails.subscribe(cld => {
    wishlistedProductCodes =
      cld?.customer?.wishlist?.entries?.filter(x => x.product !== null).map(x => x.product?.code) || [];
  });

  $: product = $$props;

  onMount(() => {
    if (
      !wishlistedProductCodes.length &&
      getAppConfig()?.algoliaConfig?.wishlistProducts?.length &&
      !$cartLoginDetails
    ) {
      wishlistedProductCodes = [...getAppConfig()?.algoliaConfig.wishlistProducts];
    }
  });

  function redirectToProductDetail(url) {
    // Check if a URL is provided
    if (!url) {
      // If not, attempt to use urlEn or fallback to productDataForGTM
      url = urlEn ? `${encodedContextPath}${urlEn}` : `${encodedContextPath}${productDataForGTM?.urlEn}`;
    }

    // Ensure we have a valid URL before proceeding
    if (!url) {
      console.error('No valid URL found for redirection.');
      return; // Early exit if no URL is available
    }

    // Track the item if the tracking method exists
    if ('extragtm' in window['ACC']) {
      window['ACC'].extragtm.click.trackSelectItem(categoryCode, categoryName, productDataForGTM);
    }

    // Redirect to the product detail page
    redirectToPdp(url);
  }

  function onClickJoodRevealGoldPrice(data) {
    if (data?.detail?.redirectUrl) {
      window.location.assign(data.detail.redirectUrl);
    } else {
      data.detail.event.stopPropagation();
      data.detail.event.preventDefault();
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  on:click={() => redirectToProductDetail(pdpUrl)}
  class="position-relative tile-content-wrapper"
  style:--mobile-tile-width="{mobileTileWidth}px"
  style:--desktop-tile-width="{desktopTileWidth}px"
  in:fade
>
  <div
    class="product-tile-container {primeCardClass} {isBundleProduct ? 'product-tile-container-bundleMinHeight' : ''}"
    style:--tile-height="calc(100%)"
  >
    <section class="tags-fav-action-section">
      {#if (showProductTags && productTags?.length) || showFavIcon}
        <ProductTileHead
          {isRRComponent}
          {product}
          {productTags}
          {showFavIcon}
          showTags={showProductTags}
          {favIconMobileWidth}
          {favIconDesktopWidth}
          isWishlisted={wishlisted}
          productCode={code}
          {productDataForGTM}
          on:changeWishList={data => {
            toggleWishlist(data);
          }}
        />
      {/if}
    </section>

    {#if amplienceProductBaseUrl}
      <section class="product-image-section">
        <ProductTileImage
          {code}
          {amplienceProductBaseUrl}
          mobileWidth={mobileImageWidth}
          desktopWidth={desktopImageWidth}
          mobileHeight={mobileImageHeight}
          desktopHeight={desktopImageHeight}
        />
      </section>
    {/if}

    <section class="product-title-section">
      {#if brandName && name}
        <ProductTileName {brandName} {name} />
      {/if}
    </section>

    {#if showRatingReview && rating}
      <section
        class="product-rating-review-section {showPrice && price && Object.keys(price).length ? 'reserved-space' : ''}"
      >
        <ProductTileRatingReviews {rating} reviews={numberOfReviews} />
      </section>
    {/if}

    {#if showPrice && price && Object.keys(price).length && !product?.comingSoon}
      <section class="product-price-section">
        <ProductTilePriceNewGroup
          isMegasaleProduct={productTags?.length ? checkForMegaSaleProduct(productTags) : []}
          variant="product-tile"
          product={{
            price: sellingPrice,
            basicPrimePrice,
            vipPrimePrice,
            wasPrice,
            priceValueTasheel,
            tacticalBasicPromo,
            tacticalVipPromo,
            tacticalVipPrimePrice,
            tacticalBasicPrimePrice,
            hasCashBack,
            cashback,
            priceValueDiscountPercentageFlag,
            plpEligibleForTamara,
            plpEligibleForTamaraBlue,
            plpEligibleForTamaraGold,
            plpEligibleForTabby,
          }}
        />
      </section>

      {#if showOfferAndGift && (offersLabel || freeGiftCount)}
        <section class="product-offer-section">
          <ProductTileOffer {offersLabel} {freeGiftCount} />
        </section>
      {/if}
    {/if}
    {#if product?.comingSoon}
      <div class="coming-soon">
        {getStr('extra.soonmessage')}
      </div>
    {/if}

    {#if showActionBtn}
      <section class="product-action-btn-section">
        <ProductTileActionBtn />
      </section>
    {/if}

    <!-- {#if showBottomTags}
            <section class="bottom-tags-section">
            <ProductBottomTags tagsData={productBottomTags}/>
            </section>
        {/if} -->
  </div>
  {#if showBottomTags && productBottomTags?.length}
    <section class="bottom-tags-section">
      <ProductBottomTags
        tagsData={productBottomTags}
        isMegasaleProduct={productTags?.length ? checkForMegaSaleProduct(productTags) : []}
      />
    </section>
  {/if}
</div>

<style lang="scss">
  @import '../../styles/colors.new.scss';
  .tile-content-wrapper {
    width: max-content;
    min-height: 100%;
    cursor: pointer;
    .product-tile-container {
      position: relative;
      font-size: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px 12px 16px;
      max-width: var(--mobile-tile-width);
      border: 1px solid $secondary-gray-02;
      border-radius: 8px;
      z-index: 1;
      background-color: $primary-white;
      height: var(--tile-height);

      @include responsive {
        padding: 8px 8px 10px;
      }
      &-bundleMinHeight {
        min-height: 328px;
      }

      @include desktop-min-width {
        max-width: var(--desktop-tile-width);
        padding: 16px 16px 16px;
        gap: 12px;
      }
      .product-image-section {
        margin-top: 12px;

        @include responsive {
          margin-top: 20px;
        }
      }
      .product-title-section {
        min-height: 28px;
        @include desktop-min-width {
          min-height: 32px;
        }
      }
      .product-rating-review-section {
        &.reserved-space {
          min-height: 14px;
          @include desktop-min-width {
            min-height: 16px;
          }
        }
      }
      .coming-soon {
        font-size: 14px;
        line-height: 16px;
        color: $neutral-dark-01;
      }
    }
    .bottom-tags-section {
      // position: absolute;
      // right: 0;
      // left: 0;
      width: var(--mobile-tile-width);
      @include desktop-min-width {
        width: var(--desktop-tile-width);
      }
    }
  }
</style>
